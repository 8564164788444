<template>
  <v-layout class="layout-onboarding">
    <TheAppBar />
    <v-main>
      <!-- Page Wrapper -->
      <div class="page-wrapper">
        <router-view />
      </div>
    </v-main>
    <!-- Go to top -->
    <Footer />
  </v-layout>
</template>

<script lang="ts" setup>
import { Footer } from '@/components/deep/';
import TheAppBar from '@/components/appbar/TheAppBar.vue';
</script>

<style lang="scss" scoped>
.page-wrapper {
  height: 100%;
}
</style>
