import { createI18n } from 'vue-i18n';

// app locales
import de from '@/locales/de.json';
import en from '@/locales/en.json';
import fr from '@/locales/fr.json';
import it from '@/locales/it.json';

// DeepUiLib locales
import { locales } from '@deepcloud/deep-ui-lib';

const i18n = createI18n({
  legacy: false,
  locale: import.meta.env.VITE_I18N_LOCALE || 'de',
  fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE || 'en',
  silentTranslationWarn: true,
  warnHtmlMessage: false,
  globalInjection: true,
  messages: {
    de,
    en,
    fr,
    it
  }
});

i18n.global.mergeLocaleMessage('de', locales.de);
i18n.global.mergeLocaleMessage('fr', locales.fr);
i18n.global.mergeLocaleMessage('it', locales.it);
i18n.global.mergeLocaleMessage('en', locales.en);

export default i18n;
