import type { Plugin } from 'vue';
import { inject, reactive } from 'vue';
import { useTheme } from 'vuetify';
import { nanoid } from 'nanoid';
import { defu } from 'defu';
import MsgBox from './MsgBox.vue';
import {
  type MsgBoxKeyValue,
  type MsgBoxOptions,
  mount,
  getDefaultOptionsMsgBoxType
} from './utils.ts';

interface GlobalOptions {
  msgBox?: MsgBoxOptions;
}

type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

let globalOptions: GlobalOptions = {};

const MsgBoxPlugin: Plugin = {
  install(app, pluginOptions?: GlobalOptions) {
    if (pluginOptions) {
      globalOptions = pluginOptions;
    }
    const state = reactive<MsgBoxKeyValue['state']>({
      promiseIds: new Map()
    });

    function mountDialog(options: MsgBoxOptions) {
      const promiseId = nanoid();

      mount(
        MsgBox,
        {
          ...defu(options, pluginOptions?.msgBox ?? {}),
          promiseId
        },
        app
      );

      return new Promise((resolve, reject) => {
        state.promiseIds.set(promiseId, {
          resolve,
          reject
        });
      });
    }

    app.provide('MsgBoxKey', {
      mountDialog,
      state
    });

    app.config.globalProperties.$msgBox = (
      options: WithRequired<MsgBoxOptions, 'theme'>
    ) => {
      return mountDialog(options);
    };
  }
};

// ----------------------- MsgBox Variants -----------------------
export type MsgBoxVariants = 'success' | 'error' | 'warning' | 'info';

function getBgColorByVariant(variant: MsgBoxVariants) {
  switch (variant) {
    case 'success':
      return 'bg-green-lighten-5';
    case 'error':
      return 'bg-red-lighten-5';
    case 'warning':
      return 'bg-orange-lighten-5';
    case 'info':
      return 'bg-blue-lighten-5';
  }
}

function useMsgBox() {
  const dialog = inject('MsgBoxKey') as MsgBoxKeyValue;
  const theme = useTheme();

  function createMsgBox(options: MsgBoxOptions) {
    if (!dialog) throw new Error('Missing dialog instance');

    if (options.msgBoxType) {
      options = defu(options, getDefaultOptionsMsgBoxType(options));
    }

    return dialog.mountDialog({
      theme: theme.name.value,
      ...options
    });
  }

  function createMsgBoxVariant(
    variant: MsgBoxVariants,
    icon: string,
    variantOptions?: MsgBoxOptions
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,
    return function (options?: any) {
      return createMsgBox({
        ...defu(variantOptions, options),
        titlePrependIconProps: {
          icon,
          color: variant,
          ...variantOptions?.titlePrependIconProps
        },
        cardTitleProps: {
          color: variant,
          class: getBgColorByVariant(variant),
          ...variantOptions?.cardTitleProps
        },
        dialogProps: {
          persistent: true,
          width: 400
        }
      });
    };
  }

  return Object.assign(createMsgBox, {
    success: createMsgBoxVariant('success', 'fas fa-circle-check'),
    error: createMsgBoxVariant('error', 'fas fa-circle-exclamation', {
      btnActions: {
        confirm: {
          ...globalOptions?.msgBox?.btnActions?.confirm,
          color: 'error'
        },
        cancel: {
          ...globalOptions?.msgBox?.btnActions?.cancel,
          color: 'dark'
        }
      }
    }),
    warning: createMsgBoxVariant('warning', 'fas fa-triangle-exclamation', {
      btnActions: {
        confirm: {
          color: 'warning'
        },
        cancel: {
          ...globalOptions?.msgBox?.btnActions?.cancel,
          color: 'dark'
        }
      }
    }),
    info: createMsgBoxVariant('info', 'fas fa-circle-info')
  });
}

export { MsgBoxPlugin as default, useMsgBox };
