<template>
  <v-footer
    app
    color="grey-lighten-4"
    :absolute="computedAbsolute"
    class="py-0"
  >
    <v-container
      class="cls-container fill-height pa-0 px-2 text-center justify-center align-center text-caption"
    >
      <v-row
        justify="center"
        :class="[
          'py-4',
          'text-center',
          'black--text',
          'cls-content',
          'align-center',
          { 'cls-content-xs': $vuetify.display.xs }
        ]"
        no-gutters
      >
        <div
          :class="[
            'd-flex',
            'justify-center',
            'cls-copyright',
            { 'v-col-12': $vuetify.display.xs }
          ]"
        >
          <div>&copy; {{ new Date().getFullYear() }}</div>
          <div>DeepCloud AG</div>
        </div>
        <div v-for="link in links" :key="link.id">
          <a
            target="_blank"
            :href="getUrlForCurrentLanguage(link.url)"
            class="text-black footer-link"
          >
            {{ link.text }}
          </a>
        </div>
        <div :class="{ 'v-col-12': $vuetify.display.xs }">
          <div class="d-flex justify-center" style="height: 16px !important">
            <v-icon
              class="fas fa-shield-halved"
              color="primary"
              style="margin-right: -4px"
            />
            <v-divider class="mx-4" vertical />
            <img :src="'/svg/swiss_made.svg'" alt="swiss_made" height="16" />
          </div>
        </div>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

// utilities
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';

const props = defineProps({
  absolute: {
    type: Boolean,
    default: true
  }
});

const i18n = useI18n();
const display = useDisplay();

const computedAbsolute = computed(() => props.absolute && display.xs.value);

const links = computed(() => [
  {
    id: 'privacy_policy',
    text: i18n.t('deep_ui_lib.core.footer.link.privacy_policy'),
    url: {
      de: 'https://www.deepcloud.swiss/de/datenschutz/',
      en: 'https://www.deepcloud.swiss/privacy-policy/',
      fr: 'https://www.deepcloud.swiss/fr/politique-des-donnees/',
      it: 'https://www.deepcloud.swiss/it/protezione-dei-dati/'
    }
  },
  {
    id: 'terms_of_service',
    text: i18n.t('deep_ui_lib.core.footer.link.terms_of_service'),
    url: {
      de: 'https://www.deepcloud.swiss/legal/AB-DeepCloud-AG_DE.pdf',
      en: 'https://www.deepcloud.swiss/legal/AB-DeepCloud-AG_EN.pdf',
      fr: 'https://www.deepcloud.swiss/legal/AB-DeepCloud-AG_FR.pdf',
      it: 'https://www.deepcloud.swiss/legal/AB-DeepCloud-AG_IT.pdf'
    }
  },
  {
    id: 'impressum',
    text: i18n.t('deep_ui_lib.core.footer.link.impressum'),
    url: {
      de: 'https://www.deepcloud.swiss/de/impressum/',
      en: 'https://www.deepcloud.swiss/impressum/',
      fr: 'https://www.deepcloud.swiss/fr/mentions-legales/',
      it: 'https://www.deepcloud.swiss/it/impronta/'
    }
  },
  {
    id: 'help',
    text: i18n.t('deep_ui_lib.core.footer.link.help'),
    url: {
      de: 'https://support.deepbox.swiss/',
      en: 'https://support.deepbox.swiss/en/',
      fr: 'https://support.deepbox.swiss/fr/',
      it: 'https://support.deepbox.swiss/it/'
    }
  }
]);

function getUrlForCurrentLanguage(
  urls: Record<'de' | 'en' | 'fr' | 'it', string>
): string {
  if (i18n.locale.value in urls) {
    return urls[i18n.locale.value as keyof typeof urls];
  }
  return urls.en;
}
</script>
<style lang="scss" scoped>
.footer-link {
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
}

.cls-content,
.cls-copyright {
  column-gap: 32px;
  row-gap: 12px;
}

.cls-content-xs {
  column-gap: 12px !important;

  & > .cls-copyright {
    column-gap: 12px !important;
  }
}
</style>
