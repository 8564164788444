// Abstraction over Http Client interface
import type {
  AxiosInstance,
  AxiosRequestConfig,
  HttpClientGetOptions
} from 'axios';
import { isAxiosError } from 'axios';

interface ApiError extends Error {
  response?: unknown;
  statusCode: number;
}

export type HttpClientResponse<T> = {
  data: T;
  status: number;
  statusCode: number;
  config: AxiosRequestConfig;
};

export type HttpClientGetOptions = {
  signal: AbortController;
};

export interface HttpClient {
  get<T = unknown>(
    path: string,
    params?: Record<string, unknown> | unknown,
    options?: HttpClientGetOptions
  ): Promise<HttpClientResponse<T>>;

  post<T = unknown>(
    path: string,
    body?: unknown,
    config?: unknown
  ): Promise<HttpClientResponse<T>>;

  put<T = unknown>(
    path: string,
    body?: unknown,
    config?: unknown
  ): Promise<HttpClientResponse<T>>;

  patch<T = unknown>(
    path: string,
    body?: unknown,
    config?: unknown
  ): Promise<HttpClientResponse<T>>;

  delete<T = unknown>(
    path: string,
    config?: unknown
  ): Promise<HttpClientResponse<T>>;
}

const doAxiosHttpClientErrorHandler = (error: Error | unknown) => {
  console.error(error);
  const e: ApiError = {} as ApiError;
  if (isAxiosError(error) && error.response) {
    e.statusCode = error?.response?.status;
    e.response = error.response;
    return Promise.reject(e);
  }
  return Promise.reject(error);
};

export class AxiosHttpClient implements HttpClient {
  private axiosInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  setHeaderAcceptLanguage(lang: string) {
    this.axiosInstance.defaults.headers.common['Accept-Language'] = lang;
  }

  async get<T = unknown>(
    path: string,
    params?: Record<string, unknown> | unknown,
    options?: HttpClientGetOptions
  ): Promise<HttpClientResponse<T>> {
    try {
      const res = await this.axiosInstance.get(path, {
        params,
        signal: options?.signal
      });
      return Promise.resolve({
        data: res.data,
        status: res.status,
        config: res.config
      });
    } catch (error: Error | unknown) {
      return doAxiosHttpClientErrorHandler(error);
    }
  }

  async delete<T = unknown>(
    path: string,
    config?: AxiosRequestConfig
  ): Promise<HttpClientResponse<T>> {
    try {
      const res = await this.axiosInstance.delete(path, config);
      return Promise.resolve({
        data: res.data,
        status: res.status,
        config: res.config
      });
    } catch (error: Error | unknown) {
      return doAxiosHttpClientErrorHandler(error);
    }
  }

  async patch<T = unknown>(
    path: string,
    body?: unknown,
    config?: AxiosRequestConfig
  ): Promise<HttpClientResponse<T>> {
    try {
      const res = await this.axiosInstance.patch(path, body, config);
      return Promise.resolve({
        data: res.data,
        status: res.status,
        config: res.config
      });
    } catch (error: Error | unknown) {
      return doAxiosHttpClientErrorHandler(error);
    }
  }

  async post<T = unknown>(
    path: string,
    body?: unknown,
    config?: AxiosRequestConfig
  ): Promise<HttpClientResponse<T>> {
    try {
      const res = await this.axiosInstance.post(path, body, config);
      return Promise.resolve({
        data: res.data,
        status: res.status,
        config: res.config
      });
    } catch (error: Error | unknown) {
      return doAxiosHttpClientErrorHandler(error);
    }
  }

  async put<T = unknown>(
    path: string,
    body?: unknown,
    config?: AxiosRequestConfig
  ): Promise<HttpClientResponse<T>> {
    try {
      const res = await this.axiosInstance.put(path, body, config);
      return Promise.resolve({
        data: res.data,
        status: res.status,
        config: res.config
      });
    } catch (error: Error | unknown) {
      return doAxiosHttpClientErrorHandler(error);
    }
  }
}
