<template>
  <!-- MOBILE APPBAR -->
  <DeepHeader v-if="isMobile">
    <template #prepend>
      <slot name="mobile-prepend">
        <DeepAppSwitcher :items="appSwitchItems" />
        <v-divider vertical />
      </slot>
    </template>

    <template #prepend-end>
      <slot name="mobile-content"></slot>
    </template>

    <template #append>
      <slot name="mobile-append"></slot>
      <div v-if="hideUserMenu"></div>
      <AppBarUserAccountMenu v-else />
    </template>
  </DeepHeader>
  <!-- /MOBILE APPBAR -->
  <!-- DESKTOP APPBAR -->
  <DeepHeader
    v-else
    :logo="logo"
    :app-switch-items="appSwitchItems"
    @click:logo="onClickShowDialogAppInfo"
  >
    <template #prepend-end>
      <slot name="desktop-prepend"></slot>
    </template>
    <template #append-start>
      <slot name="desktop-append"></slot>
    </template>
    <template v-if="$slots['desktop-center']" #center>
      <v-spacer />
      <slot class="v-toolbar__center" name="desktop-center"></slot>
      <v-spacer />
    </template>
    <template #append>
      <div v-if="hideUserMenu"></div>
      <AppBarUserAccountMenu v-else />
    </template>
  </DeepHeader>
  <!-- /DESKTOP APPBAR -->

  <DialogAppInfo v-model="showDialogAppInfo" />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { DeepHeader, DeepAppSwitcher } from '@deepcloud/deep-ui-lib';
import AppBarUserAccountMenu from '@/components/appbar/AppBarUserAccountMenu.vue';
import DialogAppInfo from '@/components/dialogs/DialogAppInfo.vue';
import { useDialogAppInfo } from '@/composables/use-dialog-app-info.ts';
import { useDeepAdminUsersMeStore } from '@/stores/deepadmin/users/users-me.ts';
import { useDisplay } from 'vuetify';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  isMobile: {
    type: Boolean,
    default: false
  },
  hideUserMenu: {
    type: Boolean,
    default: false
  },
  hideLogo: {
    type: Boolean,
    default: false
  }
});
const { showDialogAppInfo, onClickShowDialogAppInfo } = useDialogAppInfo();
const deepAdminUsersMeStore = useDeepAdminUsersMeStore();

const { t } = useI18n();
const appSwitchItems = computed(() => [
  {
    key: 'deepbox',
    title: t('document_sharing_platform'),
    to: {
      name: 'organization',
      params: {
        organization: deepAdminUsersMeStore.selectedOrganization?.group_id
      }
    }
  },
  {
    key: 'deepsign',
    title: t('digital_signatures'),
    href: import.meta.env.VITE_DEEPSIGN_URL
  }
]);

const display = useDisplay();
const logo = computed(() => {
  if (props.hideLogo) {
    return undefined;
  }
  return display.mdAndUp.value
    ? {
        appId: 'deepbox',
        to: {
          name: 'organization',
          params: {
            organization: deepAdminUsersMeStore.selectedOrganization?.group_id
          }
        }
      }
    : undefined;
});
</script>

<i18n lang="json">
{
  "en": {
    "digital_signatures": "Digital signatures",
    "document_sharing_platform": "Secure document sharing"
  },
  "de": {
    "digital_signatures": "Digitale Signaturen",
    "document_sharing_platform": "Sicheres Teilen von Dokumenten"
  },
  "fr": {
    "digital_signatures": "Signatures numériques",
    "document_sharing_platform": "Partage sécurisé"
  },
  "it": {
    "digital_signatures": "Firme digitali",
    "document_sharing_platform": "Condivisione sicura di documenti"
  }
}
</i18n>
