import { useDeepAdminUsersMeStore } from '@/stores/deepadmin/users/users-me';
import { constants } from '@/constants';

export function useOrgBetaFeatures() {
  const { getOrganizationById } = useDeepAdminUsersMeStore();

  function getOrgBetaFeaturesByOrgId(orgId: string): [] {
    const org = getOrganizationById(orgId);
    return org?.metadata?.beta_features || [];
  }

  function hasOrgBetaFeatureAbaNinjaBox(orgId: string) {
    const orgBetaFeatures = getOrgBetaFeaturesByOrgId(orgId);
    return orgBetaFeatures.includes(constants.ORG_BETA_FEATURE_ABANINJA_BOX);
  }

  function isAbaNinjaBoxEnabled(orgId: string) {
    const isByEnvEnabled = import.meta.env.VITE_ABA_NINJA_ENABLED === 'true';
    return isByEnvEnabled || hasOrgBetaFeatureAbaNinjaBox(orgId);
  }

  return {
    isAbaNinjaBoxEnabled
  };
}
