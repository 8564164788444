<template>
  <v-row>
    <v-col cols="12">
      <v-text-field
        v-model="form.contact"
        :label="$t('labels.email')"
        :hint="$t('hints.we_may_contact_you_for_further_questions')"
        :placeholder="$t('placeholders.contact_email')"
        persistent-hint
        name="field-form-feedback-email"
        :rules="[validationsRules.ruleRequired, validationsRules.ruleEmail]"
      />
    </v-col>

    <v-col cols="12">
      <v-textarea
        v-model="form.text"
        :label="$t('labels.feedback_message')"
        :placeholder="$t('placeholders.enter_your_feedback')"
        variant="outlined"
        name="field-feedback-message"
        :rules="[
          validationsRules.ruleRequired,
          (v) =>
            validationsRules.ruleMaxLength(v, { length: formMessageMaxLength })
        ]"
        :counter="formMessageMaxLength"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>
import { type PropType } from 'vue';

// types & constants
import type { ContactFeedback } from './const.ts';

// utilities
import validationsRules from '@/utils/validations/rules';

const form = defineModel({
  type: Object as PropType<ContactFeedback>,
  default: () => ({
    contact: '',
    text: '',
    type: 'feedback'
  })
});

const formMessageMaxLength = 512;
</script>
