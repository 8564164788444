<template>
  <v-menu
    v-if="deepBoxOverviewStore.recents.length > 0 && $vuetify.display.smAndUp"
    v-model="recentMenu"
    location="bottom"
    :close-on-content-click="false"
  >
    <template #activator="{ props: ActivatorProps }">
      <v-btn
        v-bind="ActivatorProps"
        :class="$vuetify.display.mdAndUp ? 'pa-1 px-2' : 'pa-0'"
      >
        <div
          v-if="$vuetify.display.mdAndUp"
          class="fav-recent-menu-btn"
          data-test-id="recent-boxes"
        >
          {{ $t('main_view.recents') }}
        </div>
        <v-icon v-else class="icon-color">far fa-rotate-left</v-icon>
        <v-icon class="icon-color" :class="device.isTablet ? 'ml-0' : 'ml-1'">
          {{ recentMenu ? 'far fa-angle-up' : 'far fa-angle-down' }}
        </v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="item in deepBoxOverviewStore.recents"
        :key="item.boxNodeId"
        class="pr-2 pl-4"
      >
        <div style="width: 275px">
          <BoxItemLink
            :box="item"
            :is-shared="boxHelper.isShared(item)"
            is-recent
            show-favorite
            :show-box-name-subtitle="
              boxHelper.isShared(item) && hasMoreBoxesFromSameOrg(item)
            "
            @click:box-item="selectBox(item)"
          />
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts" setup>
import BoxItemLink from '@/components/box/BoxItemLink.vue';

import { useDeepBoxOverviewStore } from '@/stores/deepbox/overview';
import { inject, ref } from 'vue';
import type { BoxEntry } from '@/api/types/deepbox/box';
import { useBoxHelper } from '@/components/appbar/use-box-helper';
import { DeviceKey } from '@/plugins/device-detector-js.ts';

const device = inject(DeviceKey);

const recentMenu = ref(false);

const deepBoxOverviewStore = useDeepBoxOverviewStore();

const boxHelper = useBoxHelper();

function selectBox(box: BoxEntry) {
  boxHelper.selectBox(box);
  recentMenu.value = false;
}

function hasMoreBoxesFromSameOrg(item: BoxEntry) {
  const orgId = item?.company?.companyId;
  const foundOrganizations = deepBoxOverviewStore.sharedBoxes.filter(
    (box) => box?.company?.companyId === orgId
  );

  return foundOrganizations.length > 1;
}
</script>

<style scoped lang="scss">
.v-menu__content {
  border-radius: 12px !important;
}
</style>
