<template>
  <div class="v-list-item align-center">
    <slot name="item-avatar">
      <!-- INITIALS -->
      <BoxAvatar
        v-if="isShared"
        :name="box.company.displayName"
        :initials-size="boxAvatarInitialsSize"
      />
      <!-- /INITIALS -->

      <!-- ICON -->
      <BoxAvatar v-else :box-size="boxAvatarSize" />
      <!-- /ICON -->
    </slot>

    <!-- BOX NAME / BOX ORGANIZATION -->
    <slot name="item-title">
      <BoxItemTitle
        :class="{
          'flex-grow-1': !isDeadline,
          'flex-column': !isDeadline
        }"
        :is-shared="isShared"
        :is-inbox="isInbox"
        :is-recent="isRecent"
        :is-favourite="isFavourite"
        :is-deadline="isDeadline"
        :box="box"
        :box-avatar-size="boxAvatarSize"
        :box-avatar-initials-size="boxAvatarInitialsSize"
        :is-box-selection-menu="isBoxSelectionMenu"
        :show-box-name-subtitle="showBoxNameSubtitle"
      />
    </slot>
  </div>
</template>

<script lang="ts" setup>
import { type PropType } from 'vue';

// components
import BoxAvatar from '@/components/box/BoxAvatar.vue';
import BoxItemTitle from '@/components/box/BoxItemTitle.vue';

// types & constants
import type { BoxEntry } from '@/api/types/deepbox/box';

defineProps({
  isShared: {
    type: Boolean,
    default: false
  },
  isInbox: {
    type: Boolean,
    default: false
  },
  isRecent: {
    type: Boolean,
    default: false
  },
  isFavourite: {
    type: Boolean,
    default: false
  },
  isDeadline: {
    type: Boolean,
    default: false
  },
  box: {
    type: Object as PropType<BoxEntry>,
    default: () => ({})
  },
  boxAvatarSize: {
    type: [String, Number],
    default: undefined
  },
  boxAvatarInitialsSize: {
    type: String,
    default: undefined
  },
  isBoxSelectionMenu: {
    type: Boolean,
    default: false
  },
  showBoxNameSubtitle: {
    type: Boolean,
    default: false
  }
});
</script>
