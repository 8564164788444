<template>
  <v-overlay
    v-model="isLoading"
    class="align-center justify-center text-center"
    contained
    :opacity="opacity"
  >
    <svg
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      :stroke="color"
    >
      <g fill="none" fill-rule="evenodd">
        <g transform="translate(1 1)" stroke-width="2">
          <circle stroke-opacity=".25" cx="18" cy="18" r="18" />
          <path d="M36 18c0-9.94-8.06-18-18-18">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="0.8s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
    </svg>
    <div>
      {{ loadingMsg }}
    </div>
    <slot></slot>
  </v-overlay>
</template>

<script lang="ts" setup>
// utilities
import { useLoader } from '@/components/deep/loader/use-loader';

defineProps({
  opacity: {
    type: String,
    default: '0.1'
  },
  height: {
    type: Number,
    default: 64
  },
  width: {
    type: Number,
    default: 64
  },
  color: {
    type: String,
    default: '#5D4AC6'
  }
});

const { isLoading, loadingMsg, show, hide } = useLoader();

defineExpose({ show, hide });
</script>
