<template>
  <v-col
    ref="dropzoneRef"
    :class="{ dragover: hover }"
    class="dropzone"
    @drop="drop"
    @dragenter.prevent="dragenter"
    @dragleave.prevent="dragleave"
    @dragover.prevent="dragover"
  >
    <input
      ref="fileInputRef"
      :accept="acceptedExtensions"
      class="fileInput"
      multiple
      type="file"
      @input="upload"
    />
    <v-row
      align="center"
      class="d-flex flex-column"
      dense
      justify="center"
      @click="onClickFileItem"
    >
      <v-icon size="40" color="grey-lighten-1" class="ma-3">
        far fa-cloud-arrow-up
      </v-icon>
      <p class="text-body-2 text-center text-medium-emphasis">
        {{ $t('hints.attachments_drop_zone') }}
      </p>
    </v-row>
    <v-col v-if="model.length > 0" class="pa-0">
      <v-list-item v-for="item in model" :key="item.name" class="file-details">
        <template #title>
          {{ item.name }}
          <span class="ml-3 text-medium-emphasis">
            {{ humanFileSize(item.size) }}</span
          >
        </template>
        <template #append>
          <v-icon @click.stop="remove(item)">far fa-circle-xmark</v-icon>
        </template>
      </v-list-item>
    </v-col>

    <v-alert
      v-if="model.length > 0"
      :type="tooLarge ? 'error' : 'success'"
      class="mt-4 mb-0 mx-4"
      density="compact"
      variant="outlined"
    >
      <template #title>
        <div class="d-flex flex-row flex-fill text-body-2">
          {{ $t('hints.attachments_size') }}

          <v-spacer />

          {{ humanFileSize(totalSize) + ' / ' + maxFileSizeMB + ' MB' }}
        </div>
      </template>
    </v-alert>

    <div
      :class="model.length > 0 ? ' mx-4' : ''"
      class="text-center pt-3 text-caption"
    >
      {{ $t('hints.attachments_size_hint') }}
    </div>
  </v-col>
</template>

<script lang="ts" setup>
import { ref, computed, watch } from 'vue';
import { humanFileSize } from '@/utils/deep';
import helpers, { type UploadEntriesOutputFile } from '@/utils/helpers.ts';
import type { VCol } from 'vuetify/components';
import { constants } from '@/constants';

const model = defineModel({ type: Array, default: () => [] });

const emit = defineEmits(['file-too-large']);

const maxFileSizeMB = ref(5);
const hover = ref(false);
const acceptedExtensions = ref('video/*,image/*,.pdf');

const fileInputRef = ref<typeof VCol | null>(null);

function onClickFileItem() {
  fileInputRef.value.click();
}

const totalSize = computed(() => {
  let size = 0;
  model.value.forEach((file) => {
    size += file.size;
  });
  return size;
});

const tooLarge = computed(
  () => (totalSize.value / 1000000).toFixed(1) > maxFileSizeMB.value
);

watch(
  () => tooLarge.value,
  (newValue) => {
    emit('file-too-large', newValue);
  }
);

function dragleave() {
  hover.value = false;
}

function dragenter() {
  hover.value = true;
}

function dragover() {
  hover.value = true;
}

async function drop(event: DragEvent) {
  event.preventDefault();
  hover.value = false;
  const entries = await helpers.getAllFileEntries(event);
  let filesEntries: UploadEntriesOutputFile[] = [];
  entries.forEach((entry) => {
    if (entry.files && entry.files.length > 0) {
      filesEntries = [...filesEntries, ...entry.files];
    }
  });
  handleFiles(filesEntries);
}

function upload(event: DragEvent) {
  const entries = helpers.getAllFileEntriesFromButtonUpload(event);
  if (entries) {
    handleFiles(entries.files);
  }
}

function isValid(file) {
  const supportedFileExtensions = [
    constants.FILE_EXTENSION.pdf,
    constants.FILE_EXTENSION.avi,
    constants.FILE_EXTENSION.mov,
    constants.FILE_EXTENSION.wmv,
    constants.FILE_EXTENSION.gif,
    constants.FILE_EXTENSION.png,
    constants.FILE_EXTENSION.jpg,
    constants.FILE_EXTENSION.jpeg
  ];
  if (
    file.type.includes('image') ||
    file.type.includes('video') ||
    file.type.includes('pdf')
  ) {
    return true;
  }
  if (file.type === '') {
    if (
      supportedFileExtensions.includes(file.name.split('.').pop().toLowerCase())
    ) {
      return true;
    }
    console.warn(`${file.name} file's format is not supported!`);
  } else {
    console.warn(`${file.name} file's format is not supported!`);
    return false;
  }
  return false;
}

function handleFiles(files: UploadEntriesOutputFile[]) {
  const attachments = [...model.value];
  files.forEach((selectedFile) => {
    if (isValid(selectedFile.file)) {
      attachments.push(selectedFile.file);
    }
  });
  model.value = attachments;
}

function remove(file) {
  const attachments = [...model.value];
  attachments.splice(attachments.indexOf(file), 1);
  model.value = attachments;
}
</script>

<style lang="scss" scoped>
.dropzone {
  border: 1px dashed #adb5bd;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.dragover {
  background-color: rgba(93, 74, 198, 0.05);
}

input.fileInput {
  display: none;
}

.file-details {
  height: 40px;
  padding: 0;
  margin-left: 16px;
  margin-right: 16px;
  border-bottom: 1px solid #adb5bd;
}

.file-details:last-of-type {
  height: 40px;
  border-bottom: none;
}
</style>
