<template>
  <VThemeProvider :theme="theme">
    <VDialog v-bind="finalDialogProps" v-model="isOpen">
      <VCard v-bind="cardProps">
        <!-- TITLE -->
        <component
          :is="titleComponent"
          v-if="titleComponent"
          v-bind="titleComponentProps"
        />

        <VCardTitle v-else v-bind="cardTitleProps" class="pr-2">
          <div class="d-flex align-center">
            <div class="d-flex flex-grow-1 align-center icon-title-container">
              <v-icon
                v-if="titlePrependIconProps"
                v-bind="titlePrependIconProps"
                class="mr-3"
              />
              <div class="text-h4 font-weight-thin text-truncate pr-1">
                {{ title }}
              </div>
            </div>
            <v-btn
              v-if="closable"
              icon
              variant="plain"
              size="small"
              @click="cancel"
            >
              <v-icon icon="$close" />
            </v-btn>
          </div>
        </VCardTitle>

        <!-- /TITLE -->

        <v-divider v-if="!cardTitleHidden && !!content" />

        <!-- CONTENT -->
        <VCardText v-bind="cardTextProps" class="content-text">
          <component
            :is="contentComponent"
            v-if="contentComponent"
            v-bind="contentComponentProps"
          />

          <div v-else-if="content" v-html="getSanitizedHtml(content)"></div>

          <!-- MsgBox Types -->
          <VForm
            v-if="msgBoxType"
            ref="formRef"
            v-model="isFormValid"
            :class="{
              'pt-5': !!content
            }"
            :validate-on="isFormValid === undefined ? 'submit' : 'input'"
            @submit.prevent="confirm('confirm', $event)"
          >
            <VTextarea
              v-if="msgBoxType === 'reason'"
              ref="fieldInputRef"
              v-model="fieldReason"
              v-bind="msgBoxTypeFormFieldProps"
            />

            <VCheckbox
              v-if="msgBoxType === 'checkbox'"
              v-model="fieldCheckbox"
              v-bind="msgBoxTypeFormFieldProps"
            />

            <VTextField
              v-if="msgBoxType === 'password'"
              ref="fieldInputRef"
              v-model="fieldPassword"
              v-bind="msgBoxTypeFormFieldProps"
              :type="fieldPasswordInputType"
            >
              <template #append-inner>
                <v-icon
                  tabindex="-1"
                  @click="fieldPasswordIconShow = !fieldPasswordIconShow"
                >
                  {{
                    fieldPasswordIconShow ? 'fas fa-eye' : 'fas fa-eye-slash'
                  }}
                </v-icon>
              </template>
            </VTextField>
          </VForm>
          <!-- /MsgBox Types -->
        </VCardText>
        <!-- /CONTENT -->

        <v-divider
          v-if="!cardActionsHidden && Object.keys(sortedButtons).length > 0"
        />

        <!-- ACTIONS -->
        <VCardActions v-if="!btnActionsHidden" v-bind="cardActionsProps">
          <component
            :is="actionsContentComponent"
            v-if="actionsContentComponent"
            :confirmation-button-disabled="confirmationButtonDisabled"
            :cancel="cancel"
            :confirm="confirm"
          />
          <template v-else>
            <template
              v-for="(btnKey, index) in Object.keys(sortedButtons)"
              :key="index"
            >
              <VSpacer
                v-if="Object.keys(sortedButtons).length <= 2 && index === 0"
              />
              <VBtn
                v-bind="sortedButtons[btnKey]"
                @click="confirm(sortedButtons[btnKey].name)"
              >
                <span v-if="sortedButtons[btnKey].title">
                  {{ sortedButtons[btnKey].title }}
                </span>
                <span v-else-if="sortedButtons[btnKey].titleI18nKey && i18n">
                  {{ i18n.t(sortedButtons[btnKey].titleI18nKey) }}
                </span>
              </VBtn>
              <VSpacer
                v-if="Object.keys(sortedButtons).length > 2 && index === 0"
              />
            </template>
          </template>
        </VCardActions>
        <!-- /ACTIONS -->
      </VCard>
    </VDialog>
  </VThemeProvider>
</template>

<script setup lang="ts">
import {
  type Component,
  type PropType,
  computed,
  inject,
  onMounted,
  ref,
  nextTick
} from 'vue';

// components
import {
  VBtn,
  VCard,
  VCardActions,
  VCardText,
  VDialog,
  VForm,
  VSpacer,
  VThemeProvider
} from 'vuetify/components';

// types & constants
import type { MsgBoxKeyValue, MsgBoxTypes } from './utils';

// utilities
import type { VueI18n } from 'vue-i18n';
import { getSanitizedHtml } from '@/utils/helpers/html-sanitize.ts';

const props = defineProps({
  title: {
    type: String,
    default: 'Are you sure?'
  },
  titleComponent: {
    type: Object as PropType<Component>,
    default: undefined
  },
  titleComponentProps: {
    type: Object,
    default: undefined
  },
  titlePrependIconProps: {
    type: Object,
    default: undefined
  },
  content: {
    type: String,
    default: ''
  },
  contentComponent: {
    type: Object as PropType<Component>,
    default: undefined
  },
  contentComponentProps: {
    type: Object,
    default: undefined
  },
  closable: {
    type: Boolean,
    default: false
  },
  dialogProps: {
    type: Object,
    default: undefined
  },
  cardProps: {
    type: Object,
    default: undefined
  },
  cardTitleProps: {
    type: Object,
    default: undefined
  },
  cardTitleHidden: {
    type: Boolean,
    default: false
  },
  cardTextProps: {
    type: Object,
    default: undefined
  },
  cardActionsProps: {
    type: Object,
    default: undefined
  },
  cardActionsHidden: {
    type: Boolean,
    default: false
  },
  actionsContentComponent: {
    type: Object as PropType<Component>,
    default: undefined
  },
  btnActions: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,
    type: Object as PropType<Record<string, any>>,
    default: () => ({})
  },
  btnActionsShowOnly: {
    type: Array,
    default: () => []
  },
  btnActionsHidden: {
    type: Boolean,
    default: false
  },
  theme: {
    type: String,
    required: true
  },
  msgBoxType: {
    type: String as PropType<MsgBoxTypes>,
    default: undefined
  },
  msgBoxTypeFormFieldProps: {
    type: Object,
    default: undefined
  },
  destroy: {
    type: Function,
    required: true
  },
  promiseId: {
    type: String,
    required: true
  },
  i18n: {
    type: Object as PropType<VueI18n>,
    default: undefined,
    required: true
  }
});

const dialog = inject('MsgBoxKey') as MsgBoxKeyValue;
const isOpen = ref(true);

const isFormValid = ref(undefined);
const fieldInputRef = ref<HTMLInputElement | null>(null);
const fieldReason = ref('');
const fieldCheckbox = ref(false);
const fieldPassword = ref('');

const fieldPasswordIconShow = ref(false);

const fieldPasswordInputType = computed(() =>
  fieldPasswordIconShow.value ? 'text' : 'password'
);

const formRef = ref<typeof VForm>();

function clear() {
  nextTick(() => {
    formRef.value?.reset();
    resetValidation();
  });
  fieldReason.value = '';
  fieldCheckbox.value = false;
  fieldPassword.value = '';
}

function validate() {
  return formRef.value?.validate();
}

function resetValidation() {
  return formRef.value?.resetValidation();
}

function getResolvedMsgTypeValue() {
  switch (props.msgBoxType) {
    case 'reason':
      return {
        reason: fieldReason.value
      };
    case 'checkbox':
      return {
        checkbox: fieldCheckbox.value
      };
    case 'password':
      return {
        password: fieldPassword.value
      };
  }
}

async function confirm(confirmType = 'confirm', e: Event) {
  if (e) {
    e.preventDefault();
  }
  if (props.msgBoxType && confirmType === 'confirm') {
    const { valid } = await validate();
    if (!valid) return;
    else {
      dialog?.state.promiseIds
        .get(props.promiseId)
        ?.resolve?.({ action: confirmType, ...getResolvedMsgTypeValue() });
    }
  }
  dialog?.state.promiseIds.get(props.promiseId)?.resolve?.(confirmType);
  isOpen.value = false;
  clear();
}

function cancel() {
  dialog?.state.promiseIds.get(props.promiseId)?.resolve?.('cancel');
  isOpen.value = false;
  if (props.msgBoxType) {
    clear();
  }
}

onMounted(() => {
  if (['reason', 'password'].includes(props.msgBoxType)) {
    fieldInputRef.value?.focus();
  }
});

const confirmationButtonDisabled = computed(() => {
  if (!props.msgBoxType) return false;

  return (
    (props.msgBoxType === 'reason' && !fieldReason.value) ||
    (props.msgBoxType === 'checkbox' && !fieldCheckbox.value) ||
    (props.msgBoxType === 'password' && !fieldPassword.value)
  );
});

const finalDialogProps = computed(() => {
  return {
    ...props.dialogProps,
    onAfterLeave() {
      props.dialogProps?.onAfterLeave?.();
      dialog?.state.promiseIds.delete(props.promiseId);
      props.destroy();
    }
  };
});

const sortedButtons = computed(() => {
  if (props.btnActions) {
    const buttons = { ...props.btnActions };
    return Object.keys(buttons)
      .sort(
        (btn1, btn2) =>
          props.btnActionsShowOnly.indexOf(btn1) -
          props.btnActionsShowOnly.indexOf(btn2)
      )
      .filter((key) => {
        if (props.btnActionsShowOnly.length > 0) {
          return props.btnActionsShowOnly.includes(key);
        }
        return key;
      })
      .reduce((obj, key) => {
        const newObj = { ...obj };
        newObj[key] = buttons[key];
        return newObj;
      }, {});
  }
  return [];
});
</script>
<style lang="scss" scoped>
.content-text {
  padding: 14px 18px 16px !important;
}

.icon-title-container {
  min-width: 0;
}
</style>
