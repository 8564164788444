<template>
  <v-layout v-if="deepAdminUsersMeStore.selectedOrganization">
    <!-- APP BAR -->
    <TheAppBar
      :is-mobile="device.isMobile"
      :hide-user-menu="globalStore.mobileSearch"
    >
      <template #desktop-prepend>
        <div class="d-flex ga-2">
          <v-app-bar-nav-icon
            v-if="isBreakpointSmAndDownOrTabletDevice"
            slim
            density="comfortable"
            @click="drawer = !drawer"
          >
            <v-icon>far fa-bars</v-icon>
          </v-app-bar-nav-icon>
          <div
            v-if="
              deepAdminUsersMeStore.selectedOrganization &&
              !isBreakpointSmAndDownOrTabletDevice
            "
            class="organization-name-text"
          >
            {{ deepAdminUsersMeStore.selectedOrganization.display_name }}
          </div>
          <BoxSelectionMenu v-else />
        </div>
      </template>

      <template v-if="!isBreakpointSmAndDownOrTabletDevice" #desktop-center>
        <BoxSelectionMenu />
      </template>

      <template #desktop-append>
        <AppBarFavoritesMenu />
        <AppBarRecentMenu />
        <AppBarServiceMenu
          v-if="organizationId"
          :current-organization="deepAdminUsersMeStore.selectedOrganization"
          :box="deepBoxCoreStore.selectedBox"
        />
        <DeepFlowTasksMenu
          v-if="settingsDevStore.canModeFeature('DEEP_FLOW')"
          :org-id="organizationId"
          :type-id="$route.params.type"
          :box-id="$route.params.box"
        />
      </template>

      <!-- MOBILE -->
      <template v-if="globalStore.mobileSearch" #mobile-prepend>
        <v-btn
          icon="fas fa-left-long"
          class="mx-2"
          density="comfortable"
          @click="globalStore.mobileSearch = !globalStore.mobileSearch"
        >
        </v-btn>
      </template>
      <template #mobile-content>
        <div v-if="!globalStore.mobileSearch" class="d-flex ga-2">
          <BoxSelectionMenu />
        </div>
        <v-text-field
          v-else
          v-model="searchField"
          :placeholder="
            $t('placeholders.search_in_box', { box: searchPlaceholder })
          "
          autofocus
          clearable
          hide-details
          width="100%"
          density="compact"
          name="field-search"
        />
      </template>
      <template #mobile-append>
        <v-btn
          icon="far fa-magnifying-glass"
          class="mx-2"
          density="comfortable"
          @click="globalStore.mobileSearch = !globalStore.mobileSearch"
        >
        </v-btn>
      </template>
    </TheAppBar>
    <!-- /MOBILE -->
    <!-- /APP BAR -->

    <!-- NAVIGATION MENU -->
    <BoxNavigationDrawer
      v-if="!device.isMobile"
      v-model="drawer"
      :box-id="deepBoxCoreStore.selectedBox?.boxNodeId"
      :type-id="deepBoxCoreStore.selectedBox?.deepBoxNodeId"
      :organization-id="organizationId"
    />
    <!-- /NAVIGATION MENU -->

    <!-- DETAILS DRAWER -->
    <BoxDetailDrawer
      :organization-id="organizationId"
      :type-id="$route.params.type"
      :box-id="$route.params.box"
    />
    <!-- /DETAILS DRAWER -->

    <v-main class="fill-height">
      <v-container class="main-content py-0 my-0 prevent-select" fluid>
        <router-view />

        <!-- Overused subscription blocking overlay -->
        <OverlayOverusedSubscription />
        <!-- /Overused subscription blocking overlay -->

        <NodeUploadCard />
      </v-container>
    </v-main>

    <ServiceDownload />
  </v-layout>
</template>

<script lang="ts" setup>
import { ref, computed, watch, inject } from 'vue';
import debounce from 'lodash/debounce';
import last from 'lodash/last';
import BoxDetailDrawer from '@/components/box/BoxDetailDrawer.vue';
import ServiceDownload from '@/components/services/ServiceDownload.vue';
import AppBarFavoritesMenu from '@/components/appbar/AppBarFavoritesMenu.vue';
import AppBarRecentMenu from '@/components/appbar/AppBarRecentMenu.vue';
import BoxNavigationDrawer from '@/components/box/BoxNavigationDrawer.vue';
import OverlayOverusedSubscription from '@/components/overlays/OverlayOverusedSubscription.vue';
import TheAppBar from '@/components/appbar/TheAppBar.vue';
import BoxSelectionMenu from '@/components/box/BoxSelectionMenu.vue';
import { useDeepAdminUsersMeStore } from '@/stores/deepadmin/users/users-me';
import { useGlobalStore } from '@/stores/global/global';
import { useDeepBoxCoreStore } from '@/stores/deepbox/core';
import { useDeepBoxSearchStore } from '@/stores/deepbox/search';
import { useSettingsDevStore } from '@/stores/settings/settings-dev';
import { constants } from '@/constants';
import DeepFlowTasksMenu from '@/components/deepflow/DeepFlowTasksMenu.vue';
import AppBarServiceMenu from '@/components/appbar/AppBarServiceMenu.vue';
import { useDisplay } from 'vuetify';
import { DeviceKey } from '@/plugins/device-detector-js.ts';
import { useDeepBoxDeepBoxesBoxesNodesStore } from '@/stores/deepbox/deepboxes/boxes/nodes';
import { useRoute } from 'vue-router';
import { useNodeSection } from '@/composables/use-node-section.ts';
import NodeUploadCard from '@/components/node/NodeUploadCard.vue';

const device = inject(DeviceKey);
const display = useDisplay();
const route = useRoute();

const drawer = ref(true);
const searchField = ref('');

const deepAdminUsersMeStore = useDeepAdminUsersMeStore();
const deepBoxCoreStore = useDeepBoxCoreStore();
const deepBoxDeepBoxesBoxesNodesStore = useDeepBoxDeepBoxesBoxesNodesStore();
const globalStore = useGlobalStore();
const deepBoxSearchStore = useDeepBoxSearchStore();
const settingsDevStore = useSettingsDevStore();

const isBreakpointSmAndDown = computed(() => display.smAndDown.value);

const isBreakpointSmAndDownOrTabletDevice = computed(
  () => device.isTablet || isBreakpointSmAndDown.value
);

const { getNodeSectionTranslation } = useNodeSection();
const searchPlaceholder = computed(() => {
  const breadcrumb = last(globalStore.boxDetailsBreadCrumbs);
  if (breadcrumb && breadcrumb.displayName) {
    return getNodeSectionTranslation(breadcrumb.displayName);
  }
  return '';
});

const searchNodeId = computed(() => {
  const searchNode = last(globalStore.boxDetailsBreadCrumbs);
  if (!searchNode) return '';
  if (searchNode.nodeId === constants.SECTION_INBOX) {
    return deepBoxCoreStore.selectedBox?.roots?.queue;
  }
  if (searchNode.nodeId === constants.SECTION_FILES) {
    return deepBoxCoreStore.selectedBox?.roots?.files;
  }
  if (searchNode.nodeId === constants.SECTION_TRASH) {
    return deepBoxDeepBoxesBoxesNodesStore.getCurrentRootNodeId;
  }
  return searchNode.nodeId;
});

const mobileSearchPayload = computed(() => {
  return {
    nodeId: searchNodeId.value,
    query: searchField.value,
    offset: 0,
    limit: 50,
    tags: ''
  };
});

const organizationId = computed(() => route.params.organization);

watch(
  () => searchField.value,
  (newValue) => {
    if (globalStore.mobileSearch && device.isMobile) {
      deepBoxCoreStore.mobileSearchField = newValue;
      searchForMobile();
    }
  }
);

watch(
  () => globalStore.mobileSearch,
  (newValue) => {
    if (!newValue) {
      searchField.value = '';
      // reset search store when out of mobile search
      deepBoxSearchStore.$reset();
    }
  }
);

watch(
  () => isBreakpointSmAndDownOrTabletDevice.value,
  (newValue) => {
    drawer.value = !newValue;
  },
  { immediate: true }
);

const searchForMobile = debounce(async function () {
  await deepBoxSearchStore.fetchSearchResults(mobileSearchPayload.value);
}, 500);
</script>
<style lang="scss" scoped>
.organization-name-text {
  font-size: 14px !important;
  font-weight: 500;
  padding: 0 16px;
}

.main-content {
  margin-bottom: 0 !important;
}

.mobile_box_name {
  height: 20px;
  font-size: 13px;
  font-weight: bolder;
  margin: 4px 0 0;
  padding: 0;
}

.mobile_organisation_name {
  height: 20px;
  font-size: 10px;
  margin: -4px 0 0;
  padding: 0;
  color: #868e96;
}
</style>
