<template>
  <div v-if="globalStore.boxDetailsDrawer && displayItem" v-resize="onResize">
    <v-navigation-drawer
      v-model="globalStore.boxDetailsDrawer"
      style="box-shadow: inset 6px 0 12px rgba(0, 0, 0, 0.06)"
      :width="drawerWidth"
      location="right"
      disable-resize-watcher
      disable-route-watcher
      :permanent="!$vuetify.display.xs"
      :temporary="$vuetify.display.xs"
      data-test-id="details-sidebar"
    >
      <template v-if="!hidePreview">
        <div
          v-if="forcePreviewLayoutSlim || previewLayoutSlim"
          style="max-height: 150px; min-height: 150px"
          class="my-2"
        >
          <v-list-item>
            <template #prepend>
              <v-avatar color="transparent" tile size="120">
                <BoxDetailDrawerNodePreview
                  :node="displayItem"
                  width="80"
                  height="100"
                />
              </v-avatar>
            </template>
            <v-list-item-title
              class="font-weight-bold preview-layout-slim-title"
            >
              {{ truncate(displayItem.displayName, 72) }}
            </v-list-item-title>
          </v-list-item>
          <div class="preview-layout-slim-close">
            <v-icon
              name="btn-toggle-box-details-drawer"
              end
              @click="
                globalStore.boxDetailsDrawer = !globalStore.boxDetailsDrawer
              "
            >
              fas fa-xmark
            </v-icon>
          </div>
        </div>
        <div v-else class="d-flex flex-column">
          <div v-if="displayItemNodeId" class="d-flex">
            <div
              v-if="displayItem"
              class="d-flex flex-grow-1 justify-center pa-6"
            >
              <BoxDetailDrawerNodePreview :node="displayItem" width="150" />
            </div>
            <v-btn
              class="ma-2"
              name="btn-toggle-box-details-drawer"
              slim
              icon="fas fa-xmark"
              @click="
                globalStore.boxDetailsDrawer = !globalStore.boxDetailsDrawer
              "
            >
            </v-btn>
          </div>
          <div v-if="displayItemName" class="text-h6 pa-4">
            {{ displayItemName }}
          </div>
        </div>
        <v-divider />
      </template>
      <div v-else class="preview-layout-slim-close">
        <v-icon
          end
          @click.prevent="
            globalStore.boxDetailsDrawer = !globalStore.boxDetailsDrawer
          "
        >
          fas fa-xmark
        </v-icon>
      </div>
      <!--    SECTION DETAILS -->
      <div
        v-if="displayItemNodeId"
        ref="scrollbarContainerRef"
        :class="hidePreview ? 'mt-10 pt-0' : ''"
        style="overflow-y: auto"
        @scroll="onScroll"
      >
        <CardExpandable
          :id="constants.BOX_DETAILS_DRAWER_SCROLL_ID_DETAILS"
          v-model="expandDetails"
        >
          <template #title>
            <div class="text-body-2 text-high-emphasis font-weight-medium">
              {{ $t('box_details.details_drawer.details') }}
            </div>
            <v-spacer />
          </template>
          <template #content>
            <v-list class="pa-0 ma-0">
              <template
                v-for="detailsItem in detailsItems"
                :key="detailsItem.id"
              >
                <div v-if="detailsItem.show" class="d-flex flex-row">
                  <div class="mr-2 mb-2 text-medium-emphasis font-weight-thin">
                    {{ detailsItem.label }}
                  </div>
                  <div class="pr-2 flex-grow-1 text-right">
                    {{ detailsItem.value }}
                  </div>
                </div>
              </template>
            </v-list>
          </template>
        </CardExpandable>
        <!--    SECTION TAGS -->
        <template v-if="displayItemNodeId && canTag(displayItem)">
          <v-divider v-if="displayItemNodeId" />
          <CardExpandable
            v-if="displayItemNodeId"
            :id="constants.BOX_DETAILS_DRAWER_SCROLL_ID_TAGS"
            v-model="expandTags"
            :loading="deepBoxTagsStore.assignTagPending"
            :expand-btn-hidden="displayItem.tags.length === 0"
            :expand-btn-disabled="displayItem.tags.length === 0"
          >
            <template #title>
              <div class="text-medium-emphasis text-body-2">
                {{ $t('box_details.details_drawer.tags') }}
              </div>
              <v-spacer />
              <div
                v-if="displayItem.tags.length > 0"
                class="text-medium-emphasis text-body-2"
              >
                {{ displayItem.tags.length }}
                {{
                  $t(
                    'box_details.details_drawer.sections.tags.counter',
                    displayItem.tags.length
                  )
                }}
              </div>
              <v-btn
                v-else
                :disabled="
                  deepBoxTagsStore.assignTagPending || disableBtnAddTags
                "
                class="mt-2"
                variant="plain"
                color="primary"
                name="btn-box-details-drawer-tags-assign"
                @click.stop="showDialogNodeAssignTag = true"
              >
                {{ $t('box_details.details_drawer.sections.tags.assign') }}
              </v-btn>
            </template>
            <template #content>
              <CoreTag
                v-for="tag in displayItem.tags"
                :key="tag.key"
                class="mr-1 my-2"
                :tag="tag"
                closable
                @click:close="onRemoveTag(tag)"
                @click="searchByTag(tag.key)"
              />
              <v-btn
                name="btn-box-details-drawer-tags-assign"
                :disabled="deepBoxTagsStore.assignTagPending"
                class="pa-0 my-2"
                variant="plain"
                color="primary"
                @click.stop="showDialogNodeAssignTag = true"
              >
                {{ $t('box_details.details_drawer.sections.tags.assign') }}
              </v-btn>
            </template>
          </CardExpandable>
        </template>
        <!--    SECTION DEEP_PAY PAYMENTS HISTORY -->
        <template
          v-if="
            (isLocalOrDevEnv() || isIntEnv()) &&
            deepBoxBoxInfoStore.boxInfo?.company?.companyId &&
            displayItem.nodeId
          "
        >
          <v-divider />
          <DeepPayPaymentsHistoryCardExpandable
            :origin-organization-id="
              deepBoxBoxInfoStore.boxInfo.company.companyId
            "
            :type-id="typeId"
            :box-id="boxId"
            :node-id="displayItem.nodeId"
            :mime-type="displayItem?.mimeType || ''"
          />
        </template>
        <template v-if="showSectionComments">
          <v-divider />
          <!--    SECTION COMMENTS -->
          <CardExpandable
            v-if="showSectionComments"
            :id="constants.BOX_DETAILS_DRAWER_SCROLL_ID_COMMENTS"
            v-model="expandComments"
            :loading="deepBoxCommentsStore.fetchNodeCommentsPending"
            :expand-btn-hidden="
              deepBoxCommentsStore.size === 0 && !expandComments
            "
            :expand-btn-disabled="
              deepBoxCommentsStore.size === 0 && !expandComments
            "
          >
            <template #title>
              <div class="text-body-2 text-medium-emphasis">
                {{ $t('box_details.details_drawer.comments') }}
              </div>
              <v-btn
                name="btn-box-details-drawer-comment-reload"
                class="ml-1"
                :icon="true"
                size="x-small"
                slim
                @click.stop="onCommentsFetch"
              >
                <v-icon size="small">far fa-rotate</v-icon>
              </v-btn>
              <v-spacer />
              <v-btn
                v-if="deepBoxCommentsStore.size === 0 && !expandComments"
                name="btn-box-details-drawer-comment-add"
                :disabled="deepBoxTagsStore.assignTagPending"
                variant="plain"
                color="primary"
                slim
                data-test-id="file-details-comment-add"
                @click.stop="expandComments = true"
              >
                {{
                  $t('box_details.details_drawer.sections.comments.text_add')
                }}
              </v-btn>
              <div v-else class="text-body-2 text-medium-emphasis">
                {{ deepBoxCommentsStore.size }}
                {{
                  $t(
                    'box_details.details_drawer.sections.comments.counter',
                    deepBoxCommentsStore.size
                  )
                }}
              </div>
            </template>
            <template #content>
              <CoreComments
                :node-id="displayItem.nodeId"
                :autofocus="deepBoxCommentsStore.size === 0"
                @comment:add="onCommentAdd"
              />
            </template>
          </CardExpandable>
          <!--  /SECTION COMMENTS -->
        </template>
      </div>
    </v-navigation-drawer>
    <!-- Assign Tag Dialog -->
    <DialogNodeAssignTag
      v-model="showDialogNodeAssignTag"
      :organization-id="organizationId"
      :nodes="[displayItem]"
      :type-id="typeId"
      :box-id="boxId"
    />
    <!-- /Assign Tag Dialog -->
  </div>
</template>
<script lang="ts" setup>
import first from 'lodash/first';
import last from 'lodash/last';
import { truncate } from '@/utils/deep/';
import CardExpandable from '@/components/cards/CardExpandable.vue';
import BoxDetailDrawerNodePreview from '@/components/box/BoxDetailDrawerNodePreview.vue';
import CoreTag from '@/components/core/CoreTag.vue';
import CoreComments from '@/components/core/comments/CoreComments.vue';
import DeepPayPaymentsHistoryCardExpandable from '@/components/deeppay/paymentshistory/DeepPayPaymentsHistoryCardExpandable.vue';

import { constants } from '@/constants/constants';

import { useGlobalStore } from '@/stores/global/global';
import { useDeepBoxCommentsStore } from '@/stores/deepbox/nodes/comments';
import { useDeepBoxTagsStore } from '@/stores/deepbox/deepboxes/tags';
import { useDeepBoxCoreStore } from '@/stores/deepbox/core';
import { deepBoxNodesThumbnailUrlAPI } from '@/api/deepbox/nodes/nodes-thumbnail-url';
import { computed, onUnmounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue-sonner';
import type { VNavigationDrawer } from 'vuetify/components';
import { type Node } from '@/api/types/deepbox/node';
import type { Path } from '@/api/types/deepbox/path';
import type { Tag } from '@/api/types/deepbox/tag';
import DialogNodeAssignTag from '@/components/dialogs/DialogNodeAssignTag.vue';
import { useNode } from '@/composables/use-node';
import { useDisplay, useGoTo } from 'vuetify';
import { useDeepBoxDeepBoxesBoxesNodesStore } from '@/stores/deepbox/deepboxes/boxes/nodes.ts';
import { useNodePolicy } from '@/composables/use-node-policy.ts';
import { useNodeTags } from '@/composables/use-node-tags.ts';
import { useNodeSection } from '@/composables/use-node-section.ts';
import { useDeepBoxBoxInfoStore } from '@/stores/deepbox/box-info.ts';
import { isIntEnv, isLocalOrDevEnv } from '@/utils/helpers/env.ts';
import { isNodeImage, isNodePdf } from '@/utils/helpers/nodes.ts';
import { formatDateTimeByDefaultFormat } from '@/utils/helpers/date.ts';

const props = defineProps({
  hidePreview: {
    type: Boolean,
    default: false
  },
  organizationId: {
    type: String,
    default: null
  },
  typeId: {
    type: String,
    required: true
  },
  boxId: {
    type: String,
    required: true
  }
});

const route = useRoute();
const router = useRouter();
const i18n = useI18n();

const expandDetails = ref(true);
const expandComments = ref(false);
const expandTags = ref(false);
const previewLayoutSlim = ref(false);
const forcePreviewLayoutSlim = ref(false);
const thumbnailUrl = ref('');
const showDialogNodeAssignTag = ref(false);

const deepBoxCoreStore = useDeepBoxCoreStore();
const deepBoxCommentsStore = useDeepBoxCommentsStore();
const deepBoxTagsStore = useDeepBoxTagsStore();
const globalStore = useGlobalStore();
const deepBoxDeepBoxesBoxesNodesStore = useDeepBoxDeepBoxesBoxesNodesStore();
const deepBoxBoxInfoStore = useDeepBoxBoxInfoStore();

onUnmounted(() => {
  deepBoxCommentsStore.$reset();
});

const displayItem = computed(() => {
  interface DisplayItem extends Partial<Node> {
    thumbnailUrl: string;
    parentPath: Path | undefined;
  }

  const item: DisplayItem = {
    displayName: '',
    type: 'folder',
    thumbnailUrl: thumbnailUrl.value,
    tags: [],
    parentPath: undefined
  } as DisplayItem;

  if (deepBoxCoreStore.lastSelectedNode) {
    Object.assign(item, deepBoxCoreStore.lastSelectedNode?.item);
  } else if (deepBoxDeepBoxesBoxesNodesStore.selectedNodes.length === 0) {
    Object.assign(item, { ...last(globalStore.boxDetailsBreadCrumbs) });
    if (globalStore.boxDetailsBreadCrumbs.length === 1) {
      item.displayName = i18n.t(`box_details.details_drawer.${item.name}`);
    }
  } else {
    Object.assign(item, last(deepBoxDeepBoxesBoxesNodesStore.selectedNodes));
  }
  return item;
});

const disableBtnAddTags = computed(
  () => props.typeId === undefined || props.boxId === undefined
);

const { getNodeSectionTranslation } = useNodeSection();
const displayItemName = computed(() => {
  if (deepBoxCoreStore.lastSelectedNode?.item) {
    return getNodeSectionTranslation(
      deepBoxCoreStore.lastSelectedNode?.item.name
    );
  }
  return getNodeSectionTranslation(displayItem.value?.name);
});

const nodeLocation = computed(() => {
  const firstBoxDetailsBreadCrumbsItemName = first(
    globalStore.boxDetailsBreadCrumbs
  )?.name;
  if (firstBoxDetailsBreadCrumbsItemName) {
    return i18n.t(
      `box_details.details_drawer.${firstBoxDetailsBreadCrumbsItemName}`
    );
  }
  if (displayItem.value?.parentPath) {
    const firstParentPathSegmentItemName = first(
      displayItem.value?.parentPath?.segments
    )?.name;
    if (firstParentPathSegmentItemName) {
      return i18n.t(
        `box_details.details_drawer.${firstParentPathSegmentItemName}`
      );
    }
  }
  return null;
});

const detailsItems = computed(() => {
  return [
    {
      id: 'type',
      show: displayItem.value?.type,
      label: i18n.t('box_details.details_drawer.category'),
      value: i18n.t(`box_details.details_drawer.${displayItem.value?.type}`)
    },
    {
      id: 'location',
      show: nodeLocation.value,
      label: i18n.t('box_details.details_drawer.location'),
      value: nodeLocation.value
    },
    {
      id: 'display_name',
      show: displayItem.value?.ownerUser?.displayName,
      label: i18n.t('box_details.details_drawer.owner'),
      value: displayItem.value?.ownerUser?.me
        ? i18n.t('box_details.details_drawer.me')
        : displayItem.value?.ownerUser?.displayName
    },
    {
      id: 'modified_time',
      show: displayItem.value?.modified?.time,
      label: i18n.t('box_details.details_drawer.modified'),
      value: formatDateTimeByDefaultFormat(displayItem.value?.modified?.time)
    },
    {
      id: 'created_time',
      show: displayItem.value?.created?.time,
      label: i18n.t('box_details.details_drawer.created'),
      value: formatDateTimeByDefaultFormat(displayItem.value?.created?.time)
    }
  ];
});

const displayItemNodeId = computed(() => displayItem.value?.nodeId);

const isDisplayItemTypeFolder = computed(
  () => displayItem.value?.type === constants.NODE_TYPE_FOLDER
);

const { canComment, canTag } = useNodePolicy();

const showSectionComments = computed(
  () => !isDisplayItemTypeFolder.value && canComment(displayItem.value)
);

const itemTags = computed(() => displayItem.value?.tags || []);

const itemTagsCount = computed(() => itemTags.value?.length);

function searchByTag(tagKey: string) {
  deepBoxTagsStore.setTag(tagKey);
  if (props.typeId && props.boxId && route.params.organization) {
    router.push({
      name: 'organization-types-type-boxes-box-search',
      params: {
        organization: route.params.organization,
        type: props.typeId,
        box: props.boxId
      },
      query: { tags: tagKey }
    });
  }
}

function clear() {
  previewLayoutSlim.value = false;
  deepBoxCommentsStore.clearCommentsStore();
  expandTags.value = false;
  expandComments.value = false;
}

const { isNodeTypeFolder } = useNode();

async function fetchDetailsData() {
  if (deepBoxCommentsStore.fetchNodeCommentsPending) return;
  expandTags.value = itemTags.value.length > 0;
  if (displayItem.value.nodeId && !isNodeTypeFolder(displayItem.value)) {
    try {
      const res = await deepBoxNodesThumbnailUrlAPI.get(
        displayItem.value.nodeId
      );
      if (res.data) {
        thumbnailUrl.value = res.data;
      }
    } catch (e) {
      console.log(e);
    }
  }
  if (showSectionComments.value) {
    await onCommentsFetch();
    if (
      globalStore.boxDetailsDrawerScrollId ||
      deepBoxCommentsStore.comments.length > 0
    ) {
      expandComments.value = true;
    }
    // this setTimeout allow the scroll to works because expand has an
    // effect and scroll only works when card is opened
    setTimeout(async () => {
      if (globalStore.boxDetailsDrawerScrollId) {
        const id = `#${globalStore.boxDetailsDrawerScrollId}`;
        await goToSection(id);
      }
    }, 100);
  }
}

const scrollbarContainerRef = ref<HTMLElement | null>(null);

const goTo = useGoTo();

async function goToSection(id: string) {
  const options = {
    duration: 300,
    offset: -60,
    easing: 'easeInOutCubic',
    container: undefined
  };
  if (scrollbarContainerRef.value) {
    options.container = scrollbarContainerRef.value;
  }
  await goTo(id, options);
}

async function onCommentsFetch() {
  if (displayItem.value.nodeId) {
    deepBoxCommentsStore.clearNodeComments();
    deepBoxCommentsStore.resetApiParams();
    await deepBoxCommentsStore.fetchNodeComments({
      nodeId: displayItem.value?.nodeId
    });
  }
}

async function onCommentAdd() {
  const id = `#${constants.BOX_DETAILS_DRAWER_SCROLL_ID_COMMENTS}`;
  await goToSection(id);
}

const navigationDrawerRef = ref<typeof VNavigationDrawer | null>(null);

function onResize() {
  const navigationDrawerEl = navigationDrawerRef.value?.$el;
  if (navigationDrawerEl) {
    const height = navigationDrawerEl.offsetHeight;
    forcePreviewLayoutSlim.value = height < 580;
  }
}

function onScroll(e: Event) {
  const target = e.target as HTMLInputElement;
  const scrollTop = target?.scrollTop || 0;
  const scrollHeight = target?.scrollHeight || 0;
  const elHeight = target?.offsetHeight || 0;
  if (scrollHeight <= elHeight) {
    return;
  }
  if (previewLayoutSlim.value && scrollTop === 0) {
    previewLayoutSlim.value = false;
  } else if (scrollTop > 100) {
    previewLayoutSlim.value = true;
  }
}

async function onRemoveTag(tag: Tag) {
  if (!displayItemNodeId.value || !displayItem.value.tags) return;
  let tags = [...displayItem.value.tags];
  tags = tags.filter((displayItemTag) => displayItemTag.key !== tag.key);
  try {
    await deepBoxTagsStore.updateNodeTags({
      nodeId: displayItemNodeId.value,
      tags
    });
    const { updateNodeTags } = useNodeTags();
    updateNodeTags({
      nodeId: displayItemNodeId.value,
      tags
    });
  } catch (e) {
    toast.error(i18n.t('error.error_occurred'));
  }
}

watch(
  () => displayItemNodeId.value,
  () => {
    clear();
    if (isNodeImage(displayItem.value) || isNodePdf(displayItem.value)) {
      displayItem.value.thumbnailUrl = thumbnailUrl.value;
    } else {
      displayItem.value.thumbnailUrl = '';
    }
    if (globalStore.boxDetailsDrawer) {
      fetchDetailsData();
    }
  },
  { immediate: true }
);

watch(
  () => globalStore.boxDetailsDrawer,
  (newValue) => {
    if (!newValue) {
      globalStore.setBoxDetailsDrawerScrollId('');
      clear();
    }
    if (isNodeImage(displayItem.value) || isNodePdf(displayItem.value)) {
      displayItem.value.thumbnailUrl = thumbnailUrl.value;
    } else {
      displayItem.value.thumbnailUrl = '';
    }
    if (newValue) {
      fetchDetailsData();
    }
  }
);

watch(
  () => itemTagsCount.value,
  (newValue) => {
    expandTags.value = newValue > 0;
  }
);

const DRAWER_DEFAULT_WIDTH = 320;
const drawerWidth = ref(DRAWER_DEFAULT_WIDTH);

function resetDrawerWidth() {
  drawerWidth.value = DRAWER_DEFAULT_WIDTH;
}

const { xs } = useDisplay();
watch(
  () => globalStore.boxDetailsDrawer,
  (newValue) => {
    if (newValue) {
      if (xs.value) {
        drawerWidth.value = window.innerWidth;
      } else {
        resetDrawerWidth();
      }
    } else {
      resetDrawerWidth();
    }
  }
);
</script>
<style scoped lang="scss">
:deep(.v-navigation-drawer__content) {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}

.preview-layout-slim-close {
  position: absolute;
  top: 15px;
  right: 24px;
}

.preview-layout-slim-title {
  white-space: normal;
  overflow-wrap: break-word;
  margin-top: 34px;
  overflow: hidden;
  max-height: 84px;
}
</style>
