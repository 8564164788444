<template>
  <v-dialog
    v-bind="$attrs"
    ref="baseDialog"
    v-model="model"
    :scrollable="scrollable"
    :fullscreen="fullscreen"
    :height="fullscreen || display.xs.value ? '100%' : height"
    :width="fullscreen || display.xs.value ? '100%' : height"
    :max-height="fullscreen || display.xs.value ? '100%' : maxHeight"
    :max-width="fullscreen || display.xs.value ? '100%' : maxWidth"
    :no-click-animation="movable"
    :scrim="movableModel ? false : scrim"
  >
    <template v-for="(_, slotName) in $slots" #[slotName]="slotData">
      <slot :name="slotName" v-bind="slotData" />
    </template>

    <template #default="{ isActive }">
      <v-card
        ref="baseDialogCard"
        rounded="0"
        class="mx-auto"
        width="100%"
        @mousedown="onMouseDown"
        @mouseup="onMouseUp"
        @mousemove="onMouseMove"
        @mouseout="onMouseMove"
      >
        <VCardTitle
          v-if="!loading"
          class="pr-2"
          :class="{ 'cursor-move prevent-select': movableModel }"
        >
          <div class="d-flex align-center">
            <div class="d-flex flex-grow-1 align-center icon-title-container">
              <slot name="icon">
                <v-icon v-if="icon" :icon="icon" class="mr-3" />
              </slot>
              <div
                class="text-h4 text-truncate pr-1 font-weight-thin dialog-title"
              >
                <slot name="title">
                  {{ title }}
                </slot>
              </div>
            </div>
            <div class="d-flex dialog-title-actions">
              <DevBtn v-if="isLocalEnv()" variant="plain" />
              <v-btn
                v-if="showFullscreen"
                :icon="fullscreen ? 'fas fa-compress-wide' : 'fas fa-expand'"
                color="primary"
                size="small"
                variant="plain"
                @click="fullscreen = !fullscreen"
              >
              </v-btn>
              <v-btn
                v-if="movable && !fullscreen"
                v-tooltip="{
                  text: movableModel
                    ? $t('dialogs.disable_move')
                    : $t('dialogs.activate_move'),
                  openDelay: 300
                }"
                :disabled="fullscreen"
                :icon="movableModel ? 'fas fa-thumbtack' : 'far fa-thumbtack'"
                variant="plain"
                :color="movableModel ? 'primary' : undefined"
                size="small"
                @click="movableModel = !movableModel"
              >
              </v-btn>
              <v-btn
                v-if="closable && !loading"
                icon="fas fa-xmark"
                size="small"
                variant="plain"
                @click="onClose"
              >
              </v-btn>
              <slot name="toolbar-content-append" />
            </div>
          </div>
        </VCardTitle>

        <v-divider v-if="!loading && !hideTitleDivider && $slots.content" />

        <v-card-text
          ref="baseDialogCardText"
          :class="cardTextCls"
          :style="{ height: heightContent, maxHeight: maxHeightContent }"
        >
          <slot v-if="loading" name="loading">
            <div class="text-center ma-10">
              <div>
                <AppLoaderAnimation />
              </div>
              <div>
                {{ loadingText }}
              </div>
            </div>
          </slot>
          <slot v-else name="content"></slot>
        </v-card-text>

        <v-divider
          v-if="!loading && !hideActionsDivider && $slots.actions"
          class="pb-1"
        />

        <v-card-actions
          v-if="!loading && $slots.actions"
          ref="baseDialogCardActions"
          class="mb-1"
          :class="cardActionsCls"
        >
          <slot :is-active="isActive" name="actions"></slot>
        </v-card-actions>

        <v-list v-if="!loading && $slots['actions-list']" class="my-0 py-0">
          <slot name="actions-list"></slot>
        </v-list>
      </v-card>
    </template>
  </v-dialog>
</template>
<script lang="ts" setup>
import { ref, watch } from 'vue';

// components
import AppLoaderAnimation from '@/components/app/AppLoaderAnimation.vue';
import DevBtn from '@/components/dev/DevBtn.vue';

// composables
import { useDisplay } from 'vuetify';

// utilities
import { isLocalEnv } from '@/utils/helpers/env.ts';

const props = defineProps({
  title: {
    type: String,
    default: undefined
  },
  icon: {
    type: String,
    default: undefined
  },
  scrollable: {
    type: Boolean,
    default: true
  },
  cardTextCls: {
    type: String,
    default: 'pa-1'
  },
  cardActionsCls: {
    type: [String, Object],
    default: undefined
  },
  cardTitleCls: {
    type: [String, Object],
    default: undefined
  },
  toolbarTitleCls: {
    type: String,
    default: undefined
  },
  hideActionsDivider: {
    type: Boolean,
    default: false
  },
  hideTitleDivider: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  loadingText: {
    type: String,
    default: undefined
  },
  closable: {
    type: Boolean,
    default: true
  },
  showFullscreen: {
    type: Boolean,
    default: false
  },
  height: {
    type: [Number, String],
    default: undefined
  },
  maxHeight: {
    type: [Number, String],
    default: undefined
  },
  minHeight: {
    type: [Number, String],
    default: undefined
  },
  maxWidth: {
    type: [Number, String],
    default: undefined
  },
  heightContent: {
    type: [Number, String],
    default: undefined
  },
  maxHeightContent: {
    type: [Number, String],
    default: undefined
  },
  movable: {
    type: Boolean,
    default: false
  },
  scrim: {
    type: Boolean,
    default: true
  }
});

const model = defineModel({ type: Boolean, default: false });
const fullscreen = defineModel('fullscreen', { type: Boolean, default: false });

const emit = defineEmits(['close']);

const display = useDisplay();

function onClose() {
  if (!props.closable) return;
  emit('close');
  model.value = false;
}

// handle movable
const movableModel = ref(false);

interface MovableParams {
  el: HTMLElement | undefined;
  mouseStartX: number;
  mouseStartY: number;
  elStartX: number;
  elStartY: number;
  oldTransition: string;
}

const movableParams = ref<MovableParams>({} as MovableParams);

watch(
  () => fullscreen.value,
  (newValue) => {
    if (newValue) {
      movableParams.value = {} as MovableParams;
      movableModel.value = false;
    }
  }
);

function onMouseDown(e: MouseEvent) {
  const eTarget = e.target as HTMLElement;
  let allowedClassList = [
    'v-card-title',
    'icon-title-container',
    'icon-title-container',
    'dialog-title'
  ];
  const notAllowedClassList = ['dialog-title-actions'];
  allowedClassList = allowedClassList.filter(
    (i) => !notAllowedClassList.includes(i)
  );
  const targetClassList = Array.from(eTarget.classList);
  if (
    !movableModel.value ||
    !e ||
    !eTarget ||
    !props.movable ||
    !allowedClassList.some((r) => targetClassList.includes(r))
  ) {
    return;
  }
  if (props.movable) {
    e.preventDefault();
  }
  movableParams.value = {} as MovableParams;
  const closestDialog = eTarget.closest('.v-overlay__content');
  if (e.button === 0 && closestDialog) {
    // element which can be used to move element
    movableParams.value.el = closestDialog as HTMLElement; // element which should be moved
    movableParams.value.mouseStartX = e.clientX;
    movableParams.value.mouseStartY = e.clientY;
    if (movableParams.value.el) {
      movableParams.value.elStartX =
        movableParams.value.el.getBoundingClientRect().left;
      movableParams.value.elStartY =
        movableParams.value.el.getBoundingClientRect().top;
      movableParams.value.el.style.position = 'fixed';
      movableParams.value.el.style.margin = '0';
      movableParams.value.oldTransition =
        movableParams.value.el.style.transition;
      movableParams.value.el.style.transition = 'none';
    }
  }
}

function onMouseUp() {
  if (movableParams.value.el === undefined) {
    return;
  }
  movableParams.value.el.style.transition = movableParams.value.oldTransition;
  movableParams.value.el = undefined;
}

function onMouseMove(e: MouseEvent) {
  if (!movableParams.value?.el) {
    return;
  }
  if (props.movable) {
    let left;
    let top;
    if (movableParams.value.elStartX && movableParams.value.mouseStartX) {
      left =
        movableParams.value.elStartX +
        e.clientX -
        movableParams.value.mouseStartX;
    }
    if (movableParams.value.elStartY && movableParams.value.mouseStartY) {
      top =
        movableParams.value.elStartY +
        e.clientY -
        movableParams.value.mouseStartY;
    }

    if (props.movable && left && top) {
      movableParams.value.el.style.left = left + 'px';
      movableParams.value.el.style.top = top + 'px';
    } else {
      movableParams.value.el.style.left =
        Math.min(
          Math.max(left, 0),
          window.innerWidth -
            movableParams.value.el.getBoundingClientRect().width
        ) + 'px';
      movableParams.value.el.style.top =
        Math.min(
          Math.max(top, 0),
          window.innerHeight -
            movableParams.value.el.getBoundingClientRect().height
        ) + 'px';
    }
  }
}
</script>
<style lang="scss" scoped>
.icon-title-container {
  min-width: 0;
}
</style>
