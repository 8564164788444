<template>
  <v-menu
    :disabled="isMenuDisabled"
    :close-on-content-click="true"
    location="bottom"
  >
    <!-- MENU BUTTON -->
    <template #activator="{ props: ActivatorProps, isActive }">
      <v-btn
        v-if="organizations.length > 0"
        v-bind="ActivatorProps"
        :ripple="false"
        class="selected-organization-btn"
        :style="buttonStyle"
        variant="text"
      >
        <!-- DEFAULT BUTTON -->
        <div
          class="organization-name d-inline-block text-truncate"
          style="max-width: 170px"
        >
          {{ selectedOrganizationDisplayName }}
        </div>
        <!-- DEFAULT BUTTON -->
        <v-icon v-if="!hideMenuIcon" class="icon-color ml-1">
          {{ isActive ? 'far fa-angle-up' : 'far fa-angle-down' }}
        </v-icon>
      </v-btn>
    </template>
    <!-- /MENU BUTTON -->

    <!-- MENU CARD -->
    <v-card class="pa-2" scrollable>
      <v-card-text
        class="pa-0 ma-0"
        :style="{
          maxHeight: $vuetify.display.xs ? '430px' : '530px',
          overflowY: 'scroll'
        }"
      >
        <!-- ORGANIZATIONS LIST -->
        <v-list
          v-for="organization in organizations"
          :key="organization.id"
          class="py-1"
          density="compact"
        >
          <v-list-item
            :style="selectedStyle(organization)"
            :title="getOrganizationDisplayName(organization)"
            :subtitle="getCity(organization)"
            @click="changeOrganization(organization)"
          >
            <template #prepend>
              <DeepAvatar
                rounded="circle"
                :name="organization.display_name"
                class="text-body-2"
              />
            </template>
            <template
              v-if="organization.group_id === selectedOrganization.group_id"
              #append
            >
              <v-icon color="success">far fa-check</v-icon>
            </template>
          </v-list-item>
        </v-list>
        <!-- /ORGANIZATIONS LIST -->
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <!-- CREATE NEW ORGANIZATION BUTTON -->
        <VBtnSecondary
          block
          size="large"
          :href="ONBOARDING_LINKS.ONBOARDING_LINK_ACCOUNT"
          target="_self"
        >
          {{ $t('menu.create_new_deepbox') }}
        </VBtnSecondary>
        <!-- /CREATE NEW ORGANIZATION BUTTON -->
        <v-spacer />
      </v-card-actions>
    </v-card>
    <!-- /MENU CARD -->
  </v-menu>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { DeepAvatar } from '@deepcloud/deep-ui-lib';
import helpers from '@/utils/helpers.ts';
import type { VMenu } from 'vuetify/components';
import { ONBOARDING_LINKS } from '@/constants/onboarding';
import { useKeycloak } from '@/composables/auth/use-keycloak';
import { useLocalStorage } from '@vueuse/core';
import type { UserMeOrganization } from '@/api/types/deepadmin/users/user.ts';

const props = defineProps({
  organizations: {
    type: Array,
    default: () => [],
    required: true
  },
  selectedOrganization: {
    type: Object,
    default: () => ({}),
    required: true
  },
  isMenuDisabled: {
    type: Boolean,
    default: false
  },
  hideMenuIcon: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['change:organization']);

const { user } = useKeycloak();

const buttonStyle = computed(() =>
  props.organizations?.length === 1
    ? 'cursor: default; box-shadow: none;'
    : 'box-shadow: none'
);

const currentOrganization = computed(() =>
  helpers.currentOrganization(props.selectedOrganization, props.organizations)
);

const selectedOrganizationDisplayName = computed(() => {
  if (currentOrganization.value) {
    return getOrganizationDisplayName(currentOrganization.value);
  }
  return props.selectedOrganization.display_name;
});

function getCity(org: UserMeOrganization) {
  return org?.default_address?.city || '';
}

const lastOrgId = useLocalStorage(`last_org_${user.value?.sub}`, '');

function changeOrganization(org: UserMeOrganization) {
  if (org.group_id && props.selectedOrganization.group_id !== org.group_id) {
    lastOrgId.value = org.group_id;
  }
  emit('change:organization', org);
}

function selectedStyle(org: UserMeOrganization) {
  if (props.selectedOrganization.group_id === org.group_id) {
    return 'background-color: #f3f0ff; border-radius: 6px; height: 100%';
  }
  return null;
}

function getOrganizationDisplayName(org: UserMeOrganization) {
  return helpers.isPersonalSpace(org)
    ? user.value?.display_name
    : org.display_name;
}
</script>
<style lang="scss" scoped>
.selected-organization-btn {
  :deep(.v-btn__overlay) {
    background-color: transparent;
  }
}

.organization-name {
  letter-spacing: 0;
}
</style>
