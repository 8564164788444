import { useKeycloak } from '@/composables/auth/use-keycloak.ts';
import { constants } from '@/constants';
import { isLocalOrDevEnv } from '@/utils/helpers/env.ts';

export function isOnlyOfficeEnabled() {
  const { hasBetaFeature } = useKeycloak();
  return (
    import.meta.env.VITE_ONLY_OFFICE_ENABLED ||
    hasBetaFeature(constants.USER_BETA_FEATURE_ONLYOFFICE)
  );
}

export function isDeepPayPaymentOrderEnabled() {
  return (
    isLocalOrDevEnv() ||
    import.meta.env.VITE_DEEPPAY_PAYMENT_ORDER_PAY_CLIENT_ENABLED === 'true'
  );
}
