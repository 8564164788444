<template>
  <v-fab
    v-if="fab && settingsDevStore.isDevModeAllowed"
    v-bind="$attrs"
    :color="settingsDevStore.devModeEnabled ? 'success' : 'error'"
    size="small"
    icon="far fa-bug"
    @click="
      settingsDevStore.devShowSettings = !settingsDevStore.devShowSettings
    "
  >
  </v-fab>
  <v-btn
    v-else-if="!fab && settingsDevStore.isDevModeAllowed"
    v-bind="$attrs"
    :color="settingsDevStore.devModeEnabled ? 'success' : 'error'"
    size="small"
    icon="far fa-bug"
    @click="
      settingsDevStore.devShowSettings = !settingsDevStore.devShowSettings
    "
  >
  </v-btn>
</template>

<script lang="ts" setup>
import { useSettingsDevStore } from '@/stores/settings/settings-dev';

defineProps({
  fab: {
    type: Boolean,
    default: false
  }
});

const settingsDevStore = useSettingsDevStore();
</script>
