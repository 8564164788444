import { humanFileSize } from '@/utils/deep';
import { constants } from '@/constants';
import { toast } from 'vue-sonner';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { deepSignDocumentsAPI } from '@/api/deepsign/documents';

export function useDeepSign() {
  const i18n = useI18n();
  const route = useRoute();
  const router = useRouter();

  /**
   * Check is user can open the document on DeepSign
   * @param item
   * @return {Promise<boolean>}
   */
  async function canOpenDocumentOnDeepsign(item) {
    if (!item) return false;
    // TODO : this is a workaround waiting for https://jira.abacus.ch/browse/DEEPBOX-1185
    // see https://jira.abacus.ch/browse/DEEPBOX-2074

    if (item.signStatus === null) {
      return true;
    }

    try {
      //TODO: WARNING! API is deprecated
      const res = await deepSignDocumentsAPI.get({
        filterDeepboxDocumentId: item.nodeId,
        filterDocumentStatus: item.status
      });
      const resData = res?.data;
      if (
        item.policy.canSign &&
        resData.size === 0 &&
        [
          constants.DEEP_SIGN_SIGN_STATUS_IN_PROGRESS,
          constants.DEEP_SIGN_SIGN_STATUS_SIGNED
        ].includes(item.signStatus)
      ) {
        return false;
      }

      if (item.policy.canSign && resData.size === 0) {
        return true;
      }

      return resData?.size > 0;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  function getDeepSignDeepBoxUrl(path, node) {
    const signStatus = node?.signStatus || null;
    const backRoute = encodeURIComponent(path);
    let url = import.meta.env.VITE_DEEPSIGN_URL;
    switch (signStatus) {
      case constants.DEEP_SIGN_SIGN_STATUS_IN_PROGRESS:
        url += `deepbox/${node.nodeId}/pending?backRoute=${backRoute}`;
        break;
      case constants.DEEP_SIGN_SIGN_STATUS_SIGNED:
        url += `deepbox/${node.nodeId}/signed?backRoute=${backRoute}`;
        break;
      default:
        url += `deepbox/${node.nodeId}?backRoute=${backRoute}`;
    }
    return url;
  }

  function isNodeTooBigForSign(node) {
    return node.size > constants.SIGN_MAX_SIZE;
  }

  function openDeepSignDocument(node) {
    if (isNodeTooBigForSign(node)) {
      toast.error(
        i18n.t('messages.error.deepsign_file_max_size', {
          size: humanFileSize(constants.SIGN_MAX_SIZE, 0)
        })
      );
      return;
    }
    const url = getDeepSignDeepBoxUrl(route.path, node);
    window.open(url, '_self');
  }

  function getSignatureStatusIcon(node) {
    const status = node?.signStatus || '';
    switch (status) {
      case constants.DEEP_SIGN_SIGN_STATUS_IN_PROGRESS:
        return 'fas fa-hourglass-start';
      case constants.DEEP_SIGN_SIGN_STATUS_SIGNED:
        return 'far fa-circle-check';
      default:
        return 'fas fa-pen-nib';
    }
  }

  async function openDeepSignOrPreviewDocument(item) {
    if (item?.signStatus) {
      const canOpen = await canOpenDocumentOnDeepsign(item);
      if (canOpen) {
        openDeepSignDocument(item);
      } else {
        // Fallback if user can not open DeepSign
        router.push({
          name: 'node-preview',
          params: {
            node: item.nodeId
          }
        });
      }
    } else {
      openDeepSignDocument(item);
    }
  }

  function getDeepSignUrl(path, document) {
    const backRoute = encodeURIComponent(path);
    let url = import.meta.env.VITE_DEEPSIGN_URL;
    const { documentId } = document;
    url += `document/${documentId}/sign?backRoute=${backRoute}`;
    return url;
  }

  return {
    openDeepSignDocument,
    getSignatureStatusIcon,
    canOpenDocumentOnDeepsign,
    openDeepSignOrPreviewDocument,
    getDeepSignUrl
  };
}
