import type {
  AllowedComponentProps,
  App,
  Component,
  VNode,
  VNodeProps
} from 'vue';
import { createVNode, render } from 'vue';
import {
  VBtn,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VDialog,
  VTextField,
  VTextarea,
  VCheckbox
} from 'vuetify/components';
import rules from '@/utils/validations/rules';

type ExtractProps<TComponent> = TComponent extends new () => {
  $props: infer P;
}
  ? Omit<P, keyof VNodeProps | keyof AllowedComponentProps>
  : never;

// ----------------------- MsgBox Types -----------------------
export type MsgBoxTypes = 'reason' | 'checkbox' | 'password';

export interface CustomVBtnProps extends ExtractProps<typeof VBtn> {
  titleI18nKey?: string | undefined;
}

export interface MsgBoxOptions {
  title?: string;
  titleComponent?: Component;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,
  titleComponentProps?: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,
  titlePrependIconProps?: Record<string, any>;
  content?: string;
  contentComponent?: Component;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,
  contentComponentProps?: Record<string, any>;
  dialogProps?: ExtractProps<typeof VDialog>;
  cardProps?: ExtractProps<typeof VCard>;
  cardTitleProps?: ExtractProps<typeof VCardTitle>;
  cardTextProps?: ExtractProps<typeof VCardText>;
  cardActionsProps?: ExtractProps<typeof VCardActions>;
  btnActions?: Record<string, CustomVBtnProps>;
  btnActionsHidden?: boolean;
  btnActionsShowOnly?: string[];
  theme?: string;
  msgBoxType?: MsgBoxTypes;
  msgBoxTypeFormFieldProps?:
    | ExtractProps<typeof VTextField>
    | ExtractProps<typeof VCheckbox>
    | ExtractProps<typeof VTextarea>;
}

export function mount(
  component: Component,
  props: MsgBoxOptions & { promiseId: string },
  app: App
) {
  let el: HTMLElement | null = null;

  function destroy() {
    if (el) render(null, el);
    el = null;

    vNode = null;
  }

  let vNode: VNode | null = createVNode(component, {
    ...props,
    destroy
  });
  if (app && app._context) vNode.appContext = app._context;
  if (el) render(vNode, el);
  else if (typeof document !== 'undefined')
    render(vNode, (el = document.createElement('div')));

  function hotUpdateListener() {
    import.meta.hot?.off('vite:beforeUpdate', hotUpdateListener);
    // TODO: Instead of destroying the component, we should update the theme and content?
    destroy();
  }

  import.meta.hot?.on('vite:beforeUpdate', hotUpdateListener);

  return { vNode, destroy, el };
}

export interface MsgBoxKeyValue {
  mountDialog: (options: MsgBoxOptions) => Promise<undefined>;
  state: {
    promiseIds: Map<
      string,
      {
        resolve: (value: unknown) => void;
        reject: (value: unknown) => void;
      }
    >;
  };
}

export function getDefaultOptionsMsgBoxType(
  options?: MsgBoxOptions
): MsgBoxOptions {
  return {
    msgBoxTypeFormFieldProps: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      variant: 'outlined',
      autofocus: true,
      rules: [
        options.msgBoxType === 'checkbox'
          ? (v) => rules.ruleRequired(v, { allowFalse: false })
          : rules.ruleRequired,
        ...(options?.msgBoxTypeFormFieldProps?.rules || [])
      ],
      ...options?.msgBoxTypeFormFieldProps
    }
  };
}
