/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import 'vuetify/styles';

// Composables
import { createVuetify } from 'vuetify';

import { de, en, fr, it } from 'vuetify/locale';

import { aliases, fa } from 'vuetify/iconsets/fa';
import { VBtn } from 'vuetify/components';
import * as directives from 'vuetify/directives';

import * as components from 'vuetify/components';
import * as labsComponents from 'vuetify/labs/components';

// Constants
import { constants } from '@/constants/constants.js';

const VUETIFY_ICONS = {
  clear: 'fas fa-xmark',
  sortAsc: 'fas fa-caret-up fa-fw', // fa-fw correct the position a bit to the right
  sortDesc: 'fas fa-caret-down fa-fw'
};

import { config, themes } from '@deepcloud/deep-ui-lib';

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  components: {
    ...components,
    ...labsComponents
  },
  directives,
  locale: {
    messages: {
      de,
      en,
      fr,
      it
    },
    locale: import.meta.env.VITE_I18N_LOCALE.split('-')[0]
  },
  theme: {
    defaultTheme: 'light',
    cspNonce: constants.CSP_NONCE,
    themes: {
      light: {
        ...themes.deepBoxLight,
        colors: {
          ...themes.abaNinjaLight.colors,
          ...themes.hoopLight.colors,
          ...themes.deepSignLight.colors,
          ...themes.deepVLight.colors,
          ...themes.deepBoxLight.colors,
          'surface-light': '#fff',
          'background-grey': '#f8f9fa'
        },
        variables: {
          'disabled-opacity': 0.7
        }
      },
      dark: {
        ...themes.deepBoxDark,
        colors: {
          ...themes.abaNinjaDark.colors,
          ...themes.hoopDark.colors,
          ...themes.deepSignDark.colors,
          ...themes.deepVDark.colors,
          ...themes.deepBoxDark.colors
        },
        variables: {
          'disabled-opacity': 0.7
        }
      }
    }
  },
  icons: {
    defaultSet: 'fa',
    aliases: {
      ...aliases,
      ...VUETIFY_ICONS,
      ...config.fa
    },
    sets: {
      fa
    }
  },
  aliases: {
    ...config.aliases,
    VBtnOutlined: VBtn,
    VBtnDev: VBtn,
    VBtnBoxActions: VBtn,
    VBtnBoxActionsIcon: VBtn
  },
  defaults: {
    ...config.defaults,
    // Alias
    VBtnOutlined: {
      style: 'text-transform: none;',
      variant: 'outlined',
      color: 'primary'
    },
    VBtnDev: {
      style: 'text-transform: none;',
      variant: 'tonal',
      color: 'warning',
      prependIcon: 'far fa-bug'
    },
    VBtnBoxActions: {
      class: 'rounded-lg text-black',
      variant: 'outlined',
      color: 'grey-lighten-3',
      VIcon: {
        color: 'grey'
      }
    },
    VBtnBoxActionsIcon: {
      class: 'rounded-lg text-black',
      variant: 'outlined',
      color: 'grey-lighten-3',
      size: 34,
      icon: true,
      VIcon: {
        color: 'grey'
      }
    },

    // Vuetify Components
    VBtn: {
      variant: 'flat',
      style: 'text-transform: none;',
      color: undefined
    },
    VIcon: {
      size: 18
    },
    VMenu: {
      VCard: {
        rounded: 'lg'
      },
      VList: {
        rounded: 'lg'
      }
    },
    VTextField: {
      variant: 'outlined',
      color: 'primary'
    },
    VTextarea: {
      variant: 'outlined',
      color: 'primary'
    },
    VSelect: {
      variant: 'outlined',
      color: 'primary',
      itemColor: 'primary'
    },
    VAutocomplete: {
      variant: 'outlined',
      color: 'primary',
      itemColor: 'primary',
      menuProps: {
        contentClass: 'rounded-lg'
      }
    },
    VCombobox: {
      color: 'primary',
      itemColor: 'primary',
      menuProps: {
        contentClass: 'rounded-lg'
      }
    },
    VStepperItem: {
      editIcon: 'far fa-pencil'
    },
    VDataTableServer: {
      VSelectionControl: {
        color: 'primary'
      }
    },
    VCheckbox: {
      color: 'primary'
    },
    VAppBar: {
      VBtn: {
        style: {
          fontSize: '14px'
        }
      }
    }
  }
});
