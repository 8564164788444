<template>
  <v-navigation-drawer
    v-bind="$attrs"
    class="prevent-select"
    ref="drawerRef"
    location="left"
    :mobile-breakpoint="0"
    :rail="rail"
    :width="drawerWidth"
  >
    <slot></slot>
    <template #append>
      <slot name="append-prepend"></slot>
      <v-list nav density="compact" slim :class="rail ? '' : 'px-0'">
        <slot name="append-list-item-prepend"></slot>
        <CoreNavigationDrawerListItem
          v-if="showCollapseMiniItem"
          :icon="rail ? 'fas fa-chevrons-right' : 'fas fa-chevrons-left'"
          :title="$t('navigation.collapse_sidebar')"
          icon-color="primary"
          link
          slim
          @click="rail = !rail"
        />
        <slot name="append-list-item"></slot>
      </v-list>
    </template>
    <slot name="append"></slot>
  </v-navigation-drawer>
</template>

<script lang="ts" setup>
import CoreNavigationDrawerListItem from '@/components/core/CoreNavigationDrawerListItem.vue';
import { computed, inject, ref } from 'vue';
import type { VNavigationDrawer } from 'vuetify/components';
import { useDisplay } from 'vuetify';
import { DeviceKey } from '@/plugins/device-detector-js.ts';

const props = defineProps({
  forceShowMiniCollapse: {
    type: Boolean,
    default: false
  }
});

const rail = defineModel('rail', { type: Boolean, default: false });

const display = useDisplay();

const device = inject(DeviceKey);

const showCollapseMiniItem = computed(
  () => props.forceShowMiniCollapse || (display.md.value && device?.isTablet)
);

const DRAWER_DEFAULT_WIDTH = 266;
const drawerWidth = ref(DRAWER_DEFAULT_WIDTH);
</script>
