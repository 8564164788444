<template>
  <v-row>
    <v-col cols="12">
      <i18n-t
        keypath="hints.check_knowledge_base"
        tag="div"
        class="text-grey-darken-1 text-body-2"
      >
        <template #link>
          <AppLinkExternal
            :href="linkKnowledgeCenter"
            :text="$t('hints.check_knowledge_base_link_text')"
          />
        </template>
      </i18n-t>
    </v-col>

    <v-col cols="12">
      <v-text-field
        v-model="form.contact"
        :label="$t('labels.email')"
        :hint="$t('hints.we_may_contact_you_for_further_questions')"
        :placeholder="$t('placeholders.contact_email')"
        variant="outlined"
        persistent-hint
        name="field-form-support-email"
        :rules="[validationsRules.ruleRequired, validationsRules.ruleEmail]"
      />
    </v-col>

    <v-col cols="12">
      <v-textarea
        v-model="form.text"
        :label="$t('labels.request_message')"
        :placeholder="$t('placeholders.enter_support_request')"
        name="field-support-message"
        variant="outlined"
        :rules="[
          validationsRules.ruleRequired,
          (v) =>
            validationsRules.ruleMaxLength(v, { length: formMessageMaxLength })
        ]"
        :counter="formMessageMaxLength"
      />
    </v-col>

    <v-col cols="12">
      <span>
        {{ $t('labels.attachments') }}
      </span>
      <FileUpload
        v-model="form.files"
        @file-too-large="attachmentsSizeTooLarge = $event"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>
import { type PropType, ref } from 'vue';
// components
import AppLinkExternal from '@/components/app/AppLinkExternal.vue';
import FileUpload from '@/components/FileUpload.vue';
// types & constants
import type { ContactSupport } from './const.ts';
// composable
import { useKnowledgeCenter } from '@/composables/use-knowledge-center.ts';
// utilities
import validationsRules from '@/utils/validations/rules';

const form = defineModel({
  type: Object as PropType<ContactSupport>,
  default: () => ({
    files: [],
    contact: '',
    text: '',
    type: 'support'
  })
});

const { linkDeepBox: linkKnowledgeCenter } = useKnowledgeCenter();

const attachmentsSizeTooLarge = ref(false);
const formMessageMaxLength = 512;
</script>
