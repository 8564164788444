import { computed, ref, watch } from 'vue';
import { acceptHMRUpdate, defineStore } from 'pinia';
import helpers from '@/utils/helpers';
import { constants } from '@/constants';
import type { Node } from '@/api/types/deepbox/node';
import type { RouteLocation } from 'vue-router';
import { useRoute } from 'vue-router';
import type { AxiosError, AxiosRequestConfig } from 'axios';
import { deepBoxDeepBoxesBoxesFilesPathAPI } from '@/api/deepbox/deepboxes/deepboxes-boxes-files-path';
import { deepBoxDeepBoxesBoxesQueueAPI } from '@/api/deepbox/deepboxes/deepboxes-boxes-queue';
import { deepBoxDeepBoxesBoxesFilesAPI } from '@/api/deepbox/deepboxes/deepboxes-boxes-files';
import { useDeepBoxCoreStore } from '@/stores/deepbox/core';
import { useDeepBoxDeepBoxesBoxesNodesStore } from '@/stores/deepbox/deepboxes/boxes/nodes';
// import Semaphore from '@/services/Semaphore.ts';
import { useDeepBoxBoxInfoStore } from '@/stores/deepbox/box-info.ts';
import { useNodeApi } from '@/composables/use-node-api.ts';
import { useFilesTree } from '@/composables/use-files-tree.ts';
import { uuidv4 } from '@/utils/helpers/uuid.ts';
import { calculateFileSizePercentage } from '@/utils/deep';
import { clone } from '@/utils/helpers/clone.ts';
import type { RouteTo } from '@/types/route-to.ts';

const TAG = '[deepbox:upload:store]';

export const POLL_MAP_STATUS = {
  ADDED: 'added',
  PROCESSING: 'processing',
  ERROR: 'error',
  WARNING: 'warning',
  COMPLETED: 'completed'
} as const;

export type PollMapStatus =
  (typeof POLL_MAP_STATUS)[keyof typeof POLL_MAP_STATUS];

interface UploadSingleFileApiConfig extends AxiosRequestConfig {
  id: number;
  section: string | null;
  waitForAnalyze?: number;
}

interface FileUpload {
  file: File;
  idFolder: string | null;
}

interface FolderUpload {
  name: string;
  parent?: string | null;
  route?: RouteTo;
  section?: string | null;
  node?: Node;
  nodeId?: Node;
}

type FolderUploadTree = Record<string, FolderUpload>;

export interface PollMapData {
  name?: string;
  parentId?: string;
  nodeId?: string;
  route?: RouteTo;
  section?: string | null;
  node?: Node;
  error?: AxiosError;
  file?: File;
}

export interface PollMapNode {
  id: number;
  data?: PollMapData;
  status: PollMapStatus;
  progress: number;
}

export interface PollMap {
  id: number;
  folders?: Record<string, PollMapNode>;
  files?: PollMapNode[];
  route: RouteTo;
  targetNode: Node;
  status: PollMapStatus;
}

// const semaphore = new Semaphore(3);

export const useDeepBoxUploadStore = defineStore('deepBoxUpload', () => {
  const pollMap = ref<PollMap[]>([]);

  const currentRoute = useRoute();
  const nodeApi = useNodeApi();

  function updatePollMapItemStatus(pollMapId: string) {
    // set pollMap state
    const checkIfSomePollMapItemHasStatus = (status: PollMapStatus) => {
      let valid = false;
      const hasFolders = Object.keys(pollMap.value[pollMapId]).includes(
        'folders'
      );

      if (hasFolders) {
        valid = Object.values(pollMap.value[pollMapId].folders).some(
          (f) => f.status === status
        );
      }

      const hasFiles = Object.keys(pollMap.value[pollMapId]).includes('files');
      if (hasFiles) {
        valid = pollMap.value[pollMapId].files.some((f) => f.status === status);
      }

      return valid;
    };

    const hasSomePollMapItemStatusProcessing =
      checkIfSomePollMapItemHasStatus('processing');

    // return early if some item is in state `processing`
    if (hasSomePollMapItemStatusProcessing) {
      updatePollMapItemById(pollMapId, {
        status: 'processing'
      });
      return;
    }

    // all PollMap items have been processed

    // set PollMap status to `completed` or `error` ( if all files failed )
    const checkIfPollMapItemHaveStatus = (status: PollMapStatus) => {
      let valid = false;
      const hasFolders = Object.keys(pollMap.value[pollMapId]).includes(
        'folders'
      );
      if (hasFolders) {
        const foldersFilteredByStatus = Object.values(
          pollMap.value[pollMapId].folders
        ).filter((f) => f.status === status);
        valid =
          foldersFilteredByStatus.length ===
          Object.values(pollMap.value[pollMapId].folders).length;
      }

      const hasFiles = Object.keys(pollMap.value[pollMapId]).includes('files');
      if (hasFiles) {
        const filesFilteredByStatus = pollMap.value[pollMapId].files.filter(
          (f) => f.status === status
        );
        valid =
          filesFilteredByStatus.length ===
          pollMap.value[pollMapId].files.length;
      }

      return valid;
    };

    const allPollMapItemHaveStatusError = checkIfPollMapItemHaveStatus('error');

    if (allPollMapItemHaveStatusError) {
      updatePollMapItemById(pollMapId, {
        status: 'error'
      });
      return;
    }

    // check if some PollMap item has status `error` or `warning`
    const hasSomePollMapItemStatusError =
      checkIfSomePollMapItemHasStatus('error');

    const hasSomePollMapItemStatusWarning =
      checkIfSomePollMapItemHasStatus('warning');

    if (hasSomePollMapItemStatusError || hasSomePollMapItemStatusWarning) {
      updatePollMapItemById(pollMapId, {
        status: 'warning'
      });
      return;
    }

    const allPollMapItemHaveStatusCompleted =
      checkIfPollMapItemHaveStatus('completed');

    if (allPollMapItemHaveStatusCompleted) {
      updatePollMapItemById(pollMapId, {
        status: 'completed'
      });
      return;
    }
  }

  function shouldRefreshState(
    targetNode: Node,
    parentNodeId: string,
    rootTargetNodeId: string,
    routeTo: RouteTo
  ) {
    /**
     *  Case 1:
     *  - Folders + Files are uploaded to the root ( documents -> section: 'files' )
     *  Case 2:
     *  - Folders + Files are uploaded to some nested folders ( documents -> section: 'fileNodes' )
     *  Case 3:
     *  - Only Folders  are uploaded to the root ( documents -> section: 'files' )
     *  Case 4:
     *  - Only Files are uploaded to some nested folders ( documents -> section: 'fileNodes' )
     *  Case 5:
     *  - Files are uploaded to root inbox ( inbox -> section: 'queue' or 'inbox' )
     *
     */

    const currentRouteSection = helpers.getSectionFromRoute(currentRoute);
    const routeToSection = helpers.getSectionFromRoute(routeTo);
    const targetSection = targetNode
      ? helpers.getUploadSectionFromMimeType(targetNode?.mimeType)
      : undefined;

    let _isSectionCurrentSection;
    if (targetNode) {
      _isSectionCurrentSection = isSectionCurrentSection(
        targetSection,
        currentRouteSection
      );
    } else {
      _isSectionCurrentSection = isSectionCurrentSection(
        routeToSection,
        currentRouteSection
      );
    }

    let refreshState;
    if (currentRoute?.params?.node) {
      refreshState = parentNodeId === currentRoute.params.node;
    } else {
      refreshState =
        _isSectionCurrentSection &&
        rootTargetNodeId === parentNodeId &&
        uploadRouteMatchCurrentRoute(routeTo, currentRoute);
    }
    return refreshState;
  }

  async function startFoldersUpload(pollMapId: number) {
    if (pollMapId === undefined || !pollMap.value[pollMapId]) return;
    updatePollMapItemById(pollMapId, {
      status: 'processing'
    });

    const deepBoxCoreStore = useDeepBoxCoreStore();
    const { refreshFilesTreeOnMove } = useFilesTree();

    const folders = pollMap.value[pollMapId].folders;
    const route = pollMap.value[pollMapId].route;
    const targetNode = pollMap.value[pollMapId].targetNode;

    console.log(TAG, 'startFoldersUpload - folders', folders);
    console.log(TAG, 'startFoldersUpload - route', route);
    console.log(TAG, 'startFoldersUpload - targetNode', targetNode);
    // if the new folder is within the rootTargetNode, update the 'deepbox' state, so that we can see it on the frontend
    const routeSection = helpers.getSectionFromRoute(route);

    const targetSection = targetNode
      ? helpers.getUploadSectionFromMimeType(targetNode?.mimeType)
      : undefined;

    let _isCurrentRouteSectionNested;

    if (targetSection) {
      _isCurrentRouteSectionNested = isCurrentRouteSectionNested(targetSection);
    } else {
      _isCurrentRouteSectionNested = isCurrentRouteSectionNested(routeSection);
    }

    let routeNodeId;
    if (_isCurrentRouteSectionNested && route?.params?.node) {
      routeNodeId = route.params.node;
    } else {
      routeNodeId = deepBoxCoreStore.selectedBox?.roots?.files;
    }

    // get the rootTargetNodeId. It can be either the files root node id or the sub-folder node id we're in
    let rootTargetNodeId;
    if (targetNode) {
      rootTargetNodeId = targetNode.nodeId;
    } else {
      rootTargetNodeId = routeNodeId;
    }

    // iterate through all the folders we have to create
    let folder;
    let parentNodeId;

    // await in for loop is needed where because the folders need to be uploaded
    // sequentially... each folder child need his parent id to be uploaded
    // and this is the easiest way to do it so far

    for (const folderKey in folders) {
      if (Object.hasOwn(folders, folderKey)) {
        folder = folders[folderKey];
        // update status
        folders[folderKey].status = 'processing';

        // get the right parentNodeId:
        // If the file has no parent name set, get the rootTargetNodeId
        parentNodeId = rootTargetNodeId;
        // Otherwise, get the parentNodeId from the folders map
        if (folder.data?.parent) {
          parentNodeId = folders[folder.data.parent].data.nodeId;
        }
        if (!parentNodeId) {
          const error = new Error(`parentNodeId can not be empty`);
          folders[folderKey] = {
            ...folders[folderKey],
            status: 'processing',
            data: { error }
          };
          throw error;
        }
        try {
          // Create the folder
          const folderNode = await createFolder({
            deepBoxNodeId: route.params.type,
            boxNodeId: route.params.box,
            parentNodeId,
            name: folder.data.name
          });
          if (folderNode) {
            console.log(TAG, 'folderNode', folderNode, folder);
            folders[folderKey] = {
              ...folders[folderKey],
              status: 'completed',
              progress: 100,
              data: {
                ...folders[folderKey].data,
                nodeId: folderNode.nodeId,
                node: folderNode,
                route
              }
            };

            const refreshState = shouldRefreshState(
              targetNode,
              parentNodeId,
              rootTargetNodeId,
              route
            );

            if (refreshState) {
              nodeApi.addFileFulfilled({
                node: folderNode,
                section: routeSection,
                route: clone(route)
              });
            }
            // add new created folder to tree
            await refreshFilesTreeOnMove(parentNodeId);
          }
        } catch (error) {
          // add error to current folder
          folder = {
            ...folder,
            status: 'error',
            data: {
              error
            }
          };
          return Promise.reject(error);
        }
      }
    }

    updatePollMapItemStatus(pollMapId);
    return Promise.resolve();
  }

  async function createFolder(payload: {
    deepBoxNodeId: string;
    boxNodeId: string;
    parentNodeId: string;
    name: string;
  }) {
    console.debug(
      'creating folder... name',
      payload.name,
      'parentNodeId',
      payload.parentNodeId
    );
    try {
      const { data } = await deepBoxDeepBoxesBoxesFilesPathAPI.postByNodeId(
        payload.deepBoxNodeId,
        payload.boxNodeId,
        payload.parentNodeId,
        [{ name: payload.name }]
      );
      if (data.length === 1) {
        return data[0].node;
      }
      return null;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function startUploadFiles(pollMapId: string) {
    if (pollMapId === undefined || !pollMap.value[pollMapId]) return;
    updatePollMapItemById(pollMapId, {
      status: 'processing'
    });

    const files = pollMap.value[pollMapId].files;
    const route = pollMap.value[pollMapId].route;
    const folders = pollMap.value[pollMapId].folders;
    const targetNode = pollMap.value[pollMapId].targetNode;

    let isInboxTargetNode: boolean;
    const deepBoxCoreStore = useDeepBoxCoreStore();

    if (targetNode) {
      isInboxTargetNode = targetNode.mimeType === constants.MIME_TYPE_INBOX;
    } else {
      isInboxTargetNode = route?.name === constants.ROUTE_QUEUE;
    }
    // save the reference of the rootTargetNodeId.
    // It can be the inboxNodeId, the filesNodeId, or the sub-folder node id,
    // depending on where we're at
    let rootTargetNodeId: string | null;
    if (isInboxTargetNode) {
      rootTargetNodeId = deepBoxCoreStore.selectedBox?.roots?.queue;
    } else if (targetNode) {
      rootTargetNodeId = targetNode.nodeId;
    } else {
      rootTargetNodeId = route?.params.node
        ? route?.params.node
        : deepBoxCoreStore.selectedBox?.roots?.files;
    }
    console.log(TAG, 'uploadFiles - folders', folders);
    console.log(TAG, 'uploadFiles - files', files);
    console.log(TAG, 'uploadFiles - route', route);
    console.log(TAG, 'uploadFiles - rootTargetNodeId', rootTargetNodeId);
    let parentNodeId;

    for (const [pollMapFileIndex, pollMapFile] of files.entries()) {
      // update pollMapItem file status
      updatePollMapItemFileById(pollMapId, pollMapFileIndex, {
        status: 'processing'
      });

      if (isInboxTargetNode) {
        // if we are in inbox, the parentNodeId is the rootTargetNodeId (inboxNodeId)
        parentNodeId = rootTargetNodeId;
      } else {
        // if we are in files, the parentNodeId is either the rootTargetNodeId or a folderNodeId
        // depending on the idFolder property of the file we're processing
        parentNodeId = rootTargetNodeId;
        if (
          pollMapFile.data.idFolder &&
          folders &&
          folders[pollMapFile.data.idFolder]?.data?.nodeId
        ) {
          parentNodeId = folders[pollMapFile.data.idFolder].data.nodeId;
        }
      }
      console.log(
        TAG,
        'uploadSingleFile',
        pollMapFile.data?.file?.name,
        parentNodeId
      );
      if (!parentNodeId) {
        // update pollMapItem file status
        const error = new Error(`parentNodeId can not be empty`);
        updatePollMapItemFileById(pollMapId, pollMapFileIndex, {
          status: 'error',
          data: { error }
        });
        throw error;
      }

      updatePollMapItemFileById(pollMapId, pollMapFileIndex, {
        status: 'processing',
        data: { name: pollMapFile.data.file.name },
        progress: 0
      });

      // semaphore.request(() =>
      await uploadFile({
        route,
        parentNodeId,
        file: pollMapFile.data.file,
        targetNode,
        pollMapId,
        pollMapFileIndex
      });
      // );
    }
    updatePollMapItemStatus(pollMapId);
  }

  async function uploadSingleFile(payload: {
    section: string | null;
    parentNodeId: string | null;
    file: File;
    typeId: string;
    boxId: string;
    pollMapId?: number;
    pollMapFileIndex?: number;
  }) {
    console.log('uploadFile - payload', payload);
    const { section, parentNodeId, file, typeId, boxId } = payload;
    console.log('uploadFile - section', section);
    const fileName = file.name;

    // stores
    const deepBoxDeepBoxesBoxesNodesStore =
      useDeepBoxDeepBoxesBoxesNodesStore();

    const id = payload.pollMapId || 0;

    // Build a config object, contain some properties for the actual api call (headers)
    // and some others to be used when we get the api call res (id, section)

    let config: UploadSingleFileApiConfig = {
      headers: { 'content-type': 'multipart/form-data' },
      id,
      section,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = calculateFileSizePercentage(
          progressEvent.loaded,
          progressEvent.total
        );
        if (payload.pollMapFileIndex !== undefined) {
          updatePollMapItemFileById(id, payload.pollMapFileIndex, {
            progress: percentCompleted,
            data: {
              uploadSize: {
                total: progressEvent.total,
                uploaded: progressEvent.loaded
              }
            }
          });
        }
      }
    };

    if (section === constants.SECTION_QUEUE) {
      config = {
        ...config,
        waitForAnalyze: 0
      };
    }

    try {
      const formData = new FormData();
      formData.append('files', file, fileName);
      let res;

      if (section === constants.SECTION_QUEUE) {
        res = await deepBoxDeepBoxesBoxesQueueAPI.post(
          typeId,
          boxId,
          formData,
          config
        );
      } else if (parentNodeId) {
        // if we're in a sub-folder, use parentNodeId
        res = await deepBoxDeepBoxesBoxesFilesAPI.postByNodeId(
          typeId,
          boxId,
          parentNodeId,
          formData,
          config
        );
      } else {
        // if we're in files root
        res = await deepBoxDeepBoxesBoxesFilesAPI.post(
          typeId,
          boxId,
          formData,
          config
        );
      }

      const { data } = res;
      if (data && data.length > 0) {
        const node = data[0];
        // ADD NODE
        deepBoxDeepBoxesBoxesNodesStore.updateNodeState(node);

        console.log(TAG, 'uploadFile completed', res);
        console.log(TAG, 'uploadFile - node', res.data);
        console.log(TAG, 'uploadFile - config', res.config);
      }

      return Promise.resolve(res);
    } catch (error) {
      console.log(TAG, 'ERROR', error);
      console.log('error details', id, payload);

      return Promise.reject(error);
    }
  }

  async function uploadFile({
    targetNode,
    route,
    parentNodeId,
    file,
    pollMapId,
    pollMapFileIndex
  }: {
    targetNode?: Node;
    route: RouteTo;
    parentNodeId: string;
    file: File;
    pollMapId: number;
    pollMapFileIndex: number;
  }) {
    const routeSection = helpers.getSectionFromRoute(route);

    const targetSection = targetNode
      ? helpers.getUploadSectionFromMimeType(targetNode?.mimeType)
      : undefined;
    let section;
    let rootTargetNodeId;
    if (targetNode) {
      section = targetSection;
      rootTargetNodeId = targetNode.nodeId;
    } else {
      section = routeSection;
      rootTargetNodeId = route?.params?.node;
    }

    console.log('uploadFile - payload', {
      targetNode,
      route,
      parentNodeId,
      file,
      pollMapId
    });
    console.log('uploadFile - section', section);
    const fileName = file.name;

    updatePollMapItemFileById(pollMapId, pollMapFileIndex, {
      status: 'processing',
      data: { section, name: fileName, route }
    });

    try {
      const res = await uploadSingleFile({
        section,
        parentNodeId,
        file,
        typeId: route?.params.type as string,
        boxId: route?.params.box as string,
        pollMapId,
        pollMapFileIndex
      });

      const resConfig: UploadSingleFileApiConfig =
        res.config as UploadSingleFileApiConfig;

      // set the COMPLETED status to the item on the internal map
      if (!pollMap.value[resConfig.id]) {
        const errorMsg = `uploadFile - ERROR. Couldn't find item with id`;
        console.log(TAG, errorMsg, pollMapId, resConfig.id);
        return Promise.reject(new Error(errorMsg));
      }

      updatePollMapItemFileById(pollMapId, pollMapFileIndex, {
        status: 'completed',
        progress: 100,
        data: {
          node: res.data[0],
          // update uploadSize values with node size value, because the node was successfully uploaded
          uploadSize: { total: res.data[0].size, uploaded: res.data[0].size }
        }
      });

      const refreshState = shouldRefreshState(
        targetNode,
        parentNodeId,
        rootTargetNodeId,
        route
      );

      // the state should only be refreshed if just uploaded node should be added to the current nodes showing
      if (refreshState) {
        // append the uploaded file to the current list of nodes, so that we can see it appearing
        nodeApi.addFileFulfilled({
          section: section,
          node: res.data[0],
          route
        });
      } else {
        // update inbox counter if add node to inbox
        if (
          [constants.SECTION_QUEUE, constants.SECTION_INBOX].includes(
            targetSection
          )
        ) {
          const deepBoxBoxInfoStore = useDeepBoxBoxInfoStore();
          deepBoxBoxInfoStore.increaseBoxInfoQueueCounter();
        }
      }

      updatePollMapItemStatus(pollMapId);

      return Promise.resolve(res);
    } catch (error) {
      console.log(TAG, 'ERROR', error);

      updatePollMapItemFileById(pollMapId, pollMapFileIndex, {
        status: 'error',
        data: { error }
      });

      updatePollMapItemStatus(pollMapId);

      // resolve the promise even when fails, because the error will be added to the pollMap
      return Promise.resolve();
    }
  }

  function updatePollMapItemById(pollMapId: number, data: PollMap) {
    if (!pollMap.value[pollMapId]) {
      console.log(
        TAG,
        `updatePollMapItemById ERROR. Couldn't find item with id`,
        pollMapId
      );
      return;
    }
    console.log(TAG, `updatePollMapItemById`, pollMapId, data);
    const newPollMap = [...pollMap.value];
    newPollMap[pollMapId] = {
      ...newPollMap[pollMapId],
      ...data
    };
    pollMap.value = newPollMap;
  }

  function updatePollMapItemFileById(
    pollMapId: number,
    pollMapFileIndex: number,
    values: PollMapNode
  ) {
    if (!pollMap.value[pollMapId]) {
      console.log(
        TAG,
        `updatePollMapItemFileById ERROR. Couldn't find item with id`,
        pollMapId
      );
      return;
    }
    console.log(TAG, `updatePollMapItemFileById`, pollMapId, pollMapFileIndex);
    const newPollMap = [...pollMap.value];
    newPollMap[pollMapId].files[pollMapFileIndex] = {
      ...newPollMap[pollMapId].files[pollMapFileIndex],
      id: values.id
        ? values.id
        : newPollMap[pollMapId].files[pollMapFileIndex].id,
      status: values.status
        ? values.status
        : newPollMap[pollMapId].files[pollMapFileIndex].status,
      progress: values.progress
        ? values.progress
        : newPollMap[pollMapId].files[pollMapFileIndex].progress,
      data: {
        ...newPollMap[pollMapId].files[pollMapFileIndex].data,
        ...values.data
      }
    };
    pollMap.value = newPollMap;

    // update pollMap item status
    updatePollMapItemStatus(pollMapId);
  }

  function setNodesToUpload(payload: {
    folders?: FolderUploadTree;
    files?: FileUpload[];
    route: RouteTo;
    targetNode: Node;
  }) {
    // add folders & files to same to pollMap
    if (payload.folders) {
      const pollMapId = pollMap.value.length;
      Object.keys(payload.folders).forEach((folderId) => {
        if (!pollMap.value[pollMapId]) {
          pollMap.value[pollMapId] = {
            id: pollMapId,
            status: 'added',
            progress: 0,
            folders: {},
            route: clone(payload.route),
            targetNode: payload.targetNode
          };
        }
        pollMap.value[pollMapId].folders[folderId] = {
          id: folderId,
          status: 'added',
          data: {
            ...payload.folders[folderId]
          },
          progress: 0
        };
      });

      // add files to pollMap
      payload.files?.forEach((file) => {
        if (!pollMap.value[pollMapId]) {
          pollMap.value[pollMapId] = {
            id: pollMapId,
            status: 'added',
            progress: 0,
            files: [],
            route: clone(payload.route),
            targetNode: payload.targetNode
          };
        }

        const data = {
          id: uuidv4(),
          status: 'added',
          progress: 0,
          data: {
            ...file
          }
        };
        if (!Object.keys(pollMap.value[pollMapId]).includes('files')) {
          pollMap.value[pollMapId] = {
            ...pollMap.value[pollMapId],
            files: []
          };
        }
        pollMap.value[pollMapId].files?.push(data);
      });
    } else if (payload.files?.length > 0) {
      // only files upload, create own PollMap for each file
      // add files to pollMap
      payload.files?.forEach((file) => {
        const pollMapId = pollMap.value.length;
        if (!pollMap.value[pollMapId]) {
          pollMap.value[pollMapId] = {
            id: pollMapId,
            status: 'added',
            progress: 0,
            files: [],
            route: clone(payload.route),
            targetNode: payload.targetNode
          };
        }

        const data = {
          id: uuidv4(),
          status: 'added',
          progress: 0,
          data: {
            ...file
          }
        };
        pollMap.value[pollMapId].files?.push(data);
      });
    }
  }

  const pollMapCount = computed(() => pollMap.value.length);

  function getPollMapByStatus(status: PollMapStatus) {
    return pollMap.value.filter((p) => p.status === status);
  }

  const pollMapAdded = computed(() => getPollMapByStatus('added'));

  const pollMapCountAdded = computed(() => pollMapAdded.value.length);

  const pollMapCountCompleted = computed(
    () => getPollMapByStatus('completed').length
  );

  const pollMapCountError = computed(() => getPollMapByStatus('error').length);

  const pollMapCountProcessing = computed(
    () => getPollMapByStatus('processing').length
  );

  const pollMapCountWarning = computed(
    () => getPollMapByStatus('warning').length
  );

  const pollMapTotalSizeUploaded = computed(() => {
    let totalUploadedSize = 0;
    pollMap.value.forEach((p) => {
      if (p.status !== 'added') {
        if (p.files && p.files.length) {
          p.files.forEach((f) => {
            if (f.data?.uploadSize?.uploaded) {
              totalUploadedSize += f.data.uploadSize.uploaded;
            }
          });
        }
      }
    });
    return totalUploadedSize;
  });

  const pollMapTotalSize = computed(() => {
    let totalSize = 0;
    pollMap.value.forEach((p) => {
      if (p.files && p.files.length) {
        p.files.forEach((f) => {
          if (f.data && f.data.file) {
            totalSize += f.data.file.size;
          }
        });
      }
    });
    return totalSize;
  });

  const pollMapAllCompleted = computed(() => {
    if (pollMapCount.value === 0) {
      return undefined;
    }
    return pollMapCountCompleted.value === pollMapCount.value;
  });

  const pollMapAllDone = computed(() => {
    if (pollMapCount.value === 0) {
      return undefined;
    }
    return pollMapCountAdded.value === 0 && pollMapCountProcessing.value === 0;
  });

  const pollMapNotDoneYetCount = computed(() => {
    if (pollMapCount.value === 0) {
      return 0;
    }
    return pollMap.value.filter(
      (p) => p.status === 'added' || p.status === 'processing'
    ).length;
  });

  async function startFoldersAndFilesUpload(pollMapId) {
    // wait until folders are created then start files upload
    await startFoldersUpload(pollMapId);
    startUploadFiles(pollMapId);
  }

  function maybeProcessNextPollMapItem() {
    pollMap.value.forEach((p, pId) => {
      if (pollMapCountProcessing.value > 0) return;
      if (p.status === 'added') {
        const hasFolders =
          Object.keys(p).includes('folders') &&
          Object.keys(p.folders).length > 0;
        const hasFiles = Object.keys(p).includes('files') && p.files.length > 0;
        if (hasFolders && hasFiles) {
          startFoldersAndFilesUpload(pId);
        } else if (hasFolders) {
          startFoldersUpload(pId);
        } else if (hasFiles) {
          startUploadFiles(pId);
        }
      }
    });
  }

  watch(
    () => pollMapCount.value,
    (newValue) => {
      if (newValue > 0) {
        maybeProcessNextPollMapItem();
      }
    }
  );

  watch(
    () => pollMapCountProcessing.value,
    (newValue) => {
      if (newValue === 0) {
        maybeProcessNextPollMapItem();
      }
    }
  );

  return {
    // pollMap
    pollMap,
    pollMapCount,
    getPollMapByStatus,
    pollMapCountCompleted,
    pollMapAdded,
    pollMapCountWarning,
    pollMapCountError,
    pollMapCountProcessing,
    pollMapTotalSizeUploaded,
    pollMapTotalSize,
    pollMapAllCompleted,
    pollMapAllDone,
    pollMapNotDoneYetCount,

    // actions
    uploadSingleFile,
    setNodesToUpload
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useDeepBoxUploadStore, import.meta.hot)
  );
}

function isSectionCurrentSection(targetSection: string, routeSection: string) {
  return routeSection === targetSection || !targetSection;
}

function isCurrentRouteSectionNested(targetSection: string) {
  return (
    targetSection &&
    [constants.SECTION_FILES_NODE, constants.SECTION_TRASH_NODE].includes(
      targetSection
    )
  );
}

function uploadRouteMatchCurrentRoute(
  uploadRoute: RouteTo,
  currentRoute: RouteLocation
) {
  if (!uploadRoute || !currentRoute) return false;
  const isSameRouteName = uploadRoute.name === currentRoute.name;
  const isSameType = uploadRoute.params.type === currentRoute.params.type;
  const isSameBox = uploadRoute.params.box === currentRoute.params.box;

  return isSameRouteName && isSameType && isSameBox;
}
