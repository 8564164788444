<template>
  <div v-if="comment" class="pa-0 ma-0">
    <v-sheet
      :color="highlight ? 'primary' : 'transparent'"
      rounded
      flat
      class="my-2"
    >
      <v-card
        flat
        :loading="comment.isLoading"
        :color="highlight ? 'grey-lighten-3' : ''"
        style="background-color: transparent"
        class="pa-2 pa-0 ma-0"
      >
        <v-list-item
          class="pa-0 ma-0"
          density="compact"
          :title="comment.created.user.displayName"
          slim
        >
          <template #prepend>
            <DeepAvatar
              class="mr-1"
              size="32"
              :color="`hsla(${comment.created.user.hue},100%,50%,0.3)`"
              :style="{
                color: `hsla(${comment.created.user.hue},10%,50%,1.0)`
              }"
            >
              <template #initials>
                {{ getInitials(comment.created.user.displayName) }}
              </template>
            </DeepAvatar>
          </template>
          <v-list-item-subtitle class="text-caption">
            <span
              v-tooltip="{
                text: formatDateTimeByDefaultFormat(comment.created.time),
                location: 'top',
                offset: 3,
                openDelay: 300
              }"
            >
              {{ getDateTimeSince(comment.created.time, i18n) }}
            </span>
            <span
              v-if="isModified"
              v-tooltip="{
                text: $t('core.comments.edited.tooltip', {
                  user: modifiedUserDisplayName,
                  date_time: formatDateTimeByDefaultFormat(modifiedTime)
                }),
                location: 'top',
                offset: 3,
                openDelay: 300
              }"
              class="pa-0 ma-0"
            >
              · {{ $t('core.comments.edited.label') }}
            </span>
          </v-list-item-subtitle>
          <template v-if="menuItems.length > 0" #append>
            <v-menu location="bottom left">
              <template #activator="{ props: ActivatorProps }">
                <v-btn
                  v-bind="ActivatorProps"
                  name="btn-comment-item-menu"
                  icon="fas fa-ellipsis-vertical"
                  slim
                  variant="text"
                  size="x-small"
                >
                </v-btn>
              </template>

              <v-list class="pa-0 ma-0" density="compact" slim>
                <v-list-item
                  v-for="item in menuItems"
                  :key="item.id"
                  :data-test-id="item.dataTestId"
                  density="compact"
                  :title="item.title"
                  @click="item.click(comment)"
                >
                  <template #prepend>
                    <v-icon size="small">
                      {{ item.icon }}
                    </v-icon>
                  </template>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-list-item>
        <CoreCommentTextarea
          v-if="comment.isEditMode"
          ref="commentTextarea"
          v-model.trim="commentEdit"
          :disabled="comment.isLoading"
          :rows="3"
          :rules="rules"
          autofocus
          edit-mode
          :counter="maxLength"
          @comment:add="onCommentEdit"
          @is-field-focused="isCommentFieldFocused = $event"
        >
          <template v-if="isCommentFieldFocused" #actions>
            <v-spacer />
            <VBtnSecondary
              name="btn-comment-item-edit-cancel"
              :disabled="comment.isLoading"
              @click="onEditModeCancel"
            >
              {{ $t('core.comments.btn.cancel') }}
            </VBtnSecondary>
            <VBtnPrimary
              name="btn-comment-item-edit-save"
              :loading="comment.isLoading"
              :disabled="!isValid"
              @click="onCommentEdit"
            >
              <template #loader>
                <v-progress-circular
                  :size="10"
                  :width="1"
                  color="white"
                  indeterminate
                />
              </template>
              {{ $t('core.comments.btn.save') }}
            </VBtnPrimary>
          </template>
        </CoreCommentTextarea>

        <v-row v-else class="pl-9 pr-2 pb-0 pt-2">
          <v-col>
            <div
              style="
                white-space: pre-line;
                font-size: 13px;
                font-weight: 400;
                line-height: 16px;
              "
            >
              {{ comment.text }}
            </div>
          </v-col>
          <v-col v-if="comment.hasMore" cols="12" align="end">
            <v-btn variant="plain" color="primary" @click="loadCompleteComment">
              {{ $t('core.comments.load_more') }}
              <span v-if="loadingComment" class="loading-ellipsis"></span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-sheet>
    <v-divider class="mt-2" />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { DeepAvatar } from '@deepcloud/deep-ui-lib';
import { getInitials } from '@/utils/deep/';
import CoreCommentTextarea from '@/components/core/comments/CoreCommentTextarea.vue';
import { useDeepBoxCommentsStore } from '@/stores/deepbox/nodes/comments';
import { useI18n } from 'vue-i18n';
import {
  formatDateTimeByDefaultFormat,
  getDateTimeSince
} from '@/utils/helpers/date.ts';

const props = defineProps({
  comment: {
    type: Object,
    default: () => ({})
  },
  nodeId: {
    type: String,
    default: ''
  },
  highlight: {
    type: Boolean,
    default: false
  },
  maxLength: {
    type: [Number, String],
    default: 1000
  },
  rules: {
    type: Array,
    default: () => []
  }
});

const emit = defineEmits([
  'comment:edit',
  'comment:update:prop',
  'menu:click:remove'
]);

const i18n = useI18n();

const deepBoxCommentsStore = useDeepBoxCommentsStore();

const commentEdit = ref('');
const isCommentFieldFocused = ref(false);
const isEditMode = ref(false);
const loadingComment = ref(false);

const menuItems = computed(() => {
  const items = [];
  if (props.comment.commentPolicy?.canEdit) {
    items.push({
      id: 'EDIT',
      title: i18n.t('core.comments.actions.edit'),
      dataTestId: 'comment-item-action-edit',
      icon: 'far fa-pen',
      click: async () => {
        if (props.comment.hasMore) {
          await deepBoxCommentsStore.fetchNodeComment({
            nodeId: props.nodeId,
            commentId: props.comment.commentId
          });
        }
        startEditing();
      }
    });
  }
  // TODO: add this item once the API allows
  // { id: 'REPORT_TO_OWNER', title: 'Report to owner' },
  if (props.comment.commentPolicy?.canPurge) {
    items.push({
      id: 'DELETE',
      dataTestId: 'comment-item-action-delete',
      icon: 'far fa-trash',
      title: i18n.t('core.comments.actions.delete'),
      click: (item) => {
        emit('menu:click:remove', item);
      }
    });
  }

  return items;
});

const isModified = computed(() => props.comment?.modified !== null);
const modifiedTime = computed(() => props.comment?.modified.time);
const modifiedUserDisplayName = computed(
  () => props.comment?.modified?.user?.displayName
);
const isValid = computed(
  () => (commentEdit.value && commentEdit.value?.length) || 0 <= props.maxLength
);

function onCommentEdit() {
  emit('comment:edit', {
    commentId: props.comment.commentId,
    text: commentEdit.value
  });
  isEditMode.value = false;
}

function onChangeProp(prop) {
  emit('comment:update:prop', {
    commentId: props.comment.commentId,
    prop
  });
}

function onEditModeCancel() {
  if (props.comment?.isEditMode) {
    onChangeProp({
      isEditMode: false
    });
  }
}

async function loadCompleteComment() {
  loadingComment.value = true;
  try {
    await deepBoxCommentsStore.fetchNodeComment({
      nodeId: props.nodeId,
      commentId: props.comment.commentId
    });
  } catch (e) {
    console.error(
      'ERROR fetching comment, commentId:',
      props.comment.commentId
    );
  } finally {
    loadingComment.value = false;
  }
}

function startEditing() {
  commentEdit.value = props.comment.text;
  onChangeProp({
    isEditMode: !props.comment?.isEditMode
  });
}
</script>
